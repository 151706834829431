import { axios, noNetwork } from './axios'
import base from './requests/base'
import europa from './requests/europa'
import staticApi from './requests/static'
import mind from './requests/mind'

import devices from './requests/devices'

import v2 from './requests/v2'

const api = { base, europa, mind, static: staticApi, devices, v2 }

export default api
export { api, axios, noNetwork }
