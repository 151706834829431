import { RouteConfig } from 'vue-router'
import CustomKeycloak from '@/libs/keycloak'

export type State = {
  user: AnyObj
  config: AnyObj
  routes: RouteConfig[]
  dynamicRoutes: string[][]
  app?: Vue
  dashboard?: Vue
  keycloak: CustomKeycloak | null
  areaId: string | null
  tenantId: string
  features: { path: string[]; theme: 'dark' | 'light'; title: string; [key: string]: any }
  baseAreas: AnyObj[]
  tags: AnyObj[]
  scene: string
}

const state: State = {
  user: {},
  config: {},
  routes: [],
  keycloak: null,
  areaId: null,
  dynamicRoutes: [],
  tenantId: '',
  features: { path: [], theme: 'dark', title: '智能商业认知系统', logoImg: '' },
  scene: '',
  baseAreas: [],
  tags: []
}

export default state
