import Vue, { VueConstructor } from 'vue'
import FilterHub from '@/components/common/FilterHub/index.vue'
import FilterItem from '@/components/common/FilterHub/FilterItem.vue'
import DateRangePicker from '@/components/common/FilterHub/Items/DateRangePicker.vue'
import IdentityCascader from '@/components/common/FilterHub/Items/IdentityCascader.vue'
import AreaTreeSelect from '@/components/common/FilterHub/Items/AreaTreeSelect.vue'
import ElSelectPlus from '@/components/common/ElSelectPlus/index.vue'

const component = (name: string, component: VueConstructor) => {
  Vue.use({
    install: () => {
      Vue.component(name, component)
    }
  })
}

component('filter-hub', FilterHub)
component('filter-item', FilterItem)
component('date-range-picker', DateRangePicker)
component('identity-cascader', IdentityCascader)
component('area-tree-select', AreaTreeSelect)
component('el-select-plus', ElSelectPlus)
