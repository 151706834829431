import { GetterTree } from 'vuex'
import { RouteConfig } from 'vue-router'
import { State } from './state'
import axios from 'axios'

const getters: GetterTree<State, State> = {
  menu(state) {
    const isUnauthorized = (route: RouteConfig) => route.meta?.auth && !route.meta.auth(state)

    const root = state.routes.find(route => route.name === '管理页面')
    const menu: Obj<string, AnyObj> = {}

    root?.children?.forEach(route => {
      if (isUnauthorized(route)) return

      const path = root.path + '/' + route.path

      menu[path] = {
        ...route,
        path,
        icon: route.meta?.icon ?? '',
        children: route.children
          ?.filter(sub => !isUnauthorized(sub))
          .map(sub => ({ ...sub, path: path + '/' + sub.path }))
      }
    })

    return menu
  },

  baseURL(state) {
    const { localServer } = state.config
    const { baseURL } = axios.defaults

    return localServer ? 'http://' + localServer + baseURL : baseURL
  }
}

export default getters
