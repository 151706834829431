import Keycloak from 'keycloak-js'
import jwt from './jwt'

export type LoginType = 'token' | 'password' | 'wechat'

type InitOption = {
  type?: LoginType
  token?: string
  realm: string
  clientId: string
  url: string
  responseMode?: ResponseModeType
  flow?: FlowType
}

interface KeycloakInstanceCustom extends Keycloak.KeycloakInstance {
  authTime?: number
}

type StorageType = 'keycloak' | 'legacy-token' | 'auth-time'

const storage = {
  getItem: (key: StorageType, status = false) => {
    const value = localStorage.getItem(key)

    if (status && value) {
      return JSON.parse(value)
    }
    return value
  },

  addItem: (key: StorageType, value: string) => {
    localStorage.setItem(key, value)
  },

  removeItem: (key: StorageType) => {
    localStorage.removeItem(key)
  },

  removeItems: (status: StorageType[]) => {
    status.map(item => storage.removeItem(item))
  }
}

export const now = () => new Date().getTime()

const tokenParse = (str: string) => JSON.parse(str)

class CustomKeycloak {
  public kcOptions = { token: '', authTime: 0, userInfo: {}, reconnecting: false }

  private kc: KeycloakInstanceCustom
  private loginType: LoginType = 'password'
  private tokenBase64 = ''
  private initOption: InitOption = {
    type: 'password',
    token: '',
    realm: 'star',
    clientId: 'star-console',
    url: '/auth/',
    responseMode: 'fragment',
    flow: 'standard'
  }

  constructor(options?: InitOption) {
    if (options) {
      this.initOption = { ...this.initOption, ...options }
    }
    this.loginType = this.initOption.type || 'password'
    this.tokenBase64 = this.initOption.token || ''

    if (this.loginType === 'token') {
      if (!this.tokenBase64) {
        throw 'token is required'
      }
    }

    if (this.loginType === 'password' || this.loginType === 'wechat') {
      const { realm, clientId, url } = this.initOption
      this.kc = Keycloak({ realm, clientId, url })
    }
  }

  public async login(options: Keycloak.KeycloakLoginOptions = {}): Promise<boolean> {
    let status = true
    const tokenBase64 = this.tokenBase64

    switch (this.loginType) {
      case 'password':
        status = await this.loginByPassword(options)
        break
      case 'token':
        await this.loginByToken(tokenBase64)
        break
      case 'wechat':
        status = await this.loginByPassword(options)
        break
    }
    return status
  }

  public logout(options: Keycloak.KeycloakLogoutOptions = {}) {
    if (this.loginType === 'password') {
      const kcData = storage.getItem('keycloak', true)
      this.kc.idToken = kcData.idToken
      storage.removeItems(['auth-time', 'legacy-token', 'keycloak'])
      if (this.kc.idToken) {
        this.kc?.logout(options)
      } else {
        location.href = '/'
      }
    } else {
      // localStorage.removeItem('type')
      storage.removeItems(['auth-time', 'legacy-token', 'keycloak'])
      location.href = '/'
    }
  }

  public async accountManagement() {
    if (this.loginType === 'password') {
      return this.kc.accountManagement()
    }
    throw 'no support account management'
  }

  public setToken(config: { [key: string]: string }) {
    if (this.kcOptions.token.length <= 0) {
      this.kcOptions.token = tokenParse(this.tokenBase64).accessToken
    }
    const accessToken = this.kcOptions.token

    const authType = 'Authorization'
    const token = 'Bearer ' + accessToken
    config[authType] = token
  }

  private async loginByPassword(options: Keycloak.KeycloakLoginOptions = {}) {
    const keycloak = storage.getItem('keycloak', true)

    if (keycloak) {
      if (this.refreshTokenNotExpire() > 0) {
        this.kcOptions.userInfo = keycloak.tokenParsed
        await this.initKc({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.token,
          checkLoginIframe: false
        })
        this.kcOptions.token = keycloak.token
      } else {
        await this.initKc()
      }

      const tokenCheck: boolean = await this.tokenCheckStrategy()

      if (!tokenCheck) {
        this.kc.login(options)

        return false
      }
    } else {
      const kc = await this.initKc()
      if (!kc.authenticated) {
        kc.login(options)
        return false
      } else {
        await this.tokenCheckStrategy()
      }
    }

    if (this.kc.authenticated) {
      this.kcOptions.userInfo = await this.kc.loadUserInfo()
      const tokenParsed: any = this.kc.tokenParsed

      this.kcOptions.authTime = tokenParsed.auth_time * 1000

      const token = this.kc.token
      if (token) this.kcOptions.token = token
    }
    storage.removeItems(['legacy-token', 'auth-time'])

    return true
  }

  private async loginByToken(token: string) {
    const tokenCheck = await this.tokenCheckStrategy()
    if (tokenCheck) {
      await this.refreshTokenByToken()
      return
    }
    const parse = tokenParse(token)
    const t = parse.accessToken
    const rt = parse.refreshToken
    const tokenParsed = jwt(t)
    const refreshTokenParsed = jwt(rt)
    const keycloak = {
      tokenParsed,
      refreshTokenParsed,
      token: this.kcOptions.token,
      refreshToken: rt
    }
    storage.addItem('legacy-token', token)
    storage.addItem('keycloak', JSON.stringify(keycloak))
    this.tokenCheckStrategy()
    location.href = location.origin + location.pathname
  }

  private async initKc(options = {}): Promise<Keycloak.KeycloakInstance> {
    const { responseMode, flow } = this.initOption

    await this.kc.init({ responseMode, flow, ...options })

    if (this.kc.authenticated) storage.addItem('keycloak', JSON.stringify(this.kc))

    return this.kc
  }

  private checkTokenExpire(type: string) {
    const kcData = storage.getItem('keycloak', true)

    if (!kcData || !kcData[type]) return -1

    const TokenParsed = kcData[type]

    if (!TokenParsed.exp) return -1

    const exp = TokenParsed.exp * 1000 - 15000

    return exp - now()
  }

  private tokenNotExpire() {
    return this.checkTokenExpire('tokenParsed')
  }

  private refreshTokenNotExpire() {
    return this.checkTokenExpire('refreshTokenParsed')
  }

  private setInteralToken(exp: number, refreshTokenFn: Function) {
    // eslint-disable-next-line no-console
    if (exp > 0) {
      setTimeout(async () => {
        const t = await refreshTokenFn()
        this.setInteralToken(t, refreshTokenFn)
      }, exp)
    }
  }

  private refreshToken = async () => {
    try {
      const refreshed = await this.kc.updateToken(-1)
      // eslint-disable-next-line no-console

      if (refreshed) {
        this.kcOptions.reconnecting = false

        const kc: Keycloak.KeycloakInstance = await this.initKc()
        if (kc.token) this.kcOptions.token = kc.token
        const tokenParsed: any = this.kc.tokenParsed
        this.kcOptions.authTime = tokenParsed.auth_time * 1000

        storage.addItem('keycloak', JSON.stringify(kc))
        const exp = kc.tokenParsed?.exp
        const iat = kc.tokenParsed?.iat
        if (exp && iat) return (exp - iat) * 1000 - 15000
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error, 'refreshToken error')
      if (this.refreshTokenNotExpire() > 0) {
        this.kcOptions.reconnecting = true

        this.setInteralToken(5000, this.refreshToken)
      } else {
        this.kcOptions.reconnecting = false

        const redirectUri = location.origin || '/'

        this.logout({ redirectUri })
      }
    }
  }

  private refreshTokenByToken = async () => {
    const kcParse = storage.getItem('keycloak', true)
    if (kcParse) {
      const { refreshToken } = kcParse
      let accessToken
      if (kcParse.token.length > 0) {
        accessToken = kcParse.token
      } else {
        accessToken = tokenParse(this.tokenBase64).accessToken
      }

      try {
        const data = await fetch('/api/v1/base/account/login/token/refresh', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `token=${encodeURIComponent(refreshToken)}`
        }).then(res => res.json())
        if (data.code === 0) {
          const parse = data.data
          const t = parse.access_token
          const rt = parse.refresh_token
          const tokenParsed = jwt(t)
          const refreshTokenParsed = jwt(rt)
          this.kcOptions.token = parse.access_token
          this.kcOptions.reconnecting = false
          // localStorage.setItem('refresh-token', parse.refresh_token)
          const keycloak = {
            tokenParsed,
            refreshTokenParsed,
            token: this.kcOptions.token,
            refreshToken: rt
          }
          this.kcOptions.userInfo = tokenParsed
          const authTime = storage.getItem('auth-time')

          if (!authTime) {
            storage.addItem('auth-time', tokenParsed.iat * 1000 + '')
            this.kcOptions.authTime = tokenParsed.iat * 1000
          } else {
            this.kcOptions.authTime = Number(authTime)
          }
          const mindToken = {
            accessToken: data.data.access_token,
            refreshToken: data.data.fresh_token
          }
          storage.addItem('legacy-token', JSON.stringify(mindToken))
          storage.addItem('keycloak', JSON.stringify(keycloak))

          const exp = tokenParsed.exp
          const iat = tokenParsed.iat
          if (exp && iat) return (exp - iat) * 1000 - 15000
        }
      } catch (error) {
        if (this.refreshTokenNotExpire() > 0) {
          this.setInteralToken(5000, this.refreshTokenByToken)
          this.kcOptions.reconnecting = true
        } else {
          this.kcOptions.reconnecting = false
          storage.removeItems(['auth-time', 'keycloak', 'legacy-token'])
          location.href = '/'
        }
      }
    }
  }

  private async tokenCheckStrategy() {
    const refresh = this.loginType === 'token' ? this.refreshTokenByToken : this.refreshToken
    // t, rt为有效期(毫秒)
    const t = this.tokenNotExpire()

    if (t <= 0) {
      const rt = this.refreshTokenNotExpire()
      if (rt <= 0) {
        storage.removeItem('legacy-token')
        return false
      }

      // 如果refresh_token没有过期，通过refresh_token拿到access_token, 并且开启刷新token定时器
      const tokenExp = await refresh()
      if (tokenExp) {
        this.setInteralToken(tokenExp, refresh)
        return true
      }
      return false
    }
    this.setInteralToken(t, refresh)
    return true
  }
}

export default CustomKeycloak
