



















































































import { Vue, Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { is } from '@/utils/helpers'
import clickOutside from '@/utils/directives/click-outside'
import { ElTree, TreeData, TreeNode as ElTreeNode } from 'element-ui/types/tree'
import { treeFind } from 'operation-tree-node'

type Record<K extends keyof any = string, T = any> = {
  [P in K]: T
}
type SingleValue = number | string
type Value = SingleValue | SingleValue[]
type TreeNode<K = SingleValue, D extends TreeData = TreeData> = ElTreeNode<K, D>

const isMultipleValue = (v: Value): v is SingleValue[] => is.arr(v)

const defaultProps = {
  value: 'value',
  label: 'label',
  children: 'children',
  disabled: 'disabled',
  isLeaf: 'isLeaf'
}

// TODO: data lazy laoding
@Component({ name: 'ElTreeSelect', directives: { clickOutside } })
export default class ElTreeSelect extends Vue {
  @Model('change', { default: () => '' }) value: Value

  /**
   * 展示数据
   */
  @Prop({ default: () => [] }) data: Record[]
  /**
   * 配置选项
   */
  @Prop({ default: () => defaultProps }) props: Record<string, string>
  /**
   * 每个树节点用来作为唯一标识的属性，整棵树应该是唯一的
   */
  @Prop() nodeKey: string
  /**
   * 输入框占位文本
   */
  @Prop({ default: '请选择' }) placeholder: string
  /**
   * placement
   */
  @Prop({ default: 'bottom-start' }) placement: string
  /**
   * 输入框尺寸
   */
  @Prop({ default: Vue.prototype.$ELEMENT ? Vue.prototype.$ELEMENT.size : '' }) size: string
  /**
   * 选项分隔符
   */
  @Prop({ default: ', ' }) separator: string
  /**
   * 是否严格的遵循父子不互相关联的做法
   */
  @Prop({ default: false, type: Boolean }) checkStrictly: boolean
  /**
   * 仅当 multiple 为 true 时生效，当父节点下的所有子节点被选中时，是否返回所有子节点的值
   */
  @Prop({ default: false, type: Boolean }) emitLeaves: boolean
  /**
   * 是否支持清空选项
   */
  @Prop({ default: false, type: Boolean }) clearable: boolean
  /**
   * 是否禁用
   */
  @Prop({ default: false, type: Boolean }) disabled: boolean
  /**
   * 是否开启多选
   */
  @Prop({ default: false, type: Boolean }) multiple: boolean
  /**
   * 是否可搜索选项
   */
  @Prop({ default: false, type: Boolean }) filterable: boolean
  /**
   * 为 popper 添加类名
   */
  @Prop({ default: '' }) popperClass: boolean

  @Ref('elTree') private elTree?: ElTree<any, any>
  @Ref('scrollbar') private scrollbar?: Vue

  private visible = false
  private searchValue = ''

  private get syncedValue() {
    return this.value
  }

  private set syncedValue(value: Value) {
    /**
     * 绑定值被改变时触发
     *
     * @property {Object} prop 组件绑定值
     */
    this.$emit('change', value)
  }

  private get entireProps() {
    return { ...defaultProps, ...this.props }
  }

  private get propsValue() {
    return this.nodeKey || this.entireProps.value
  }

  private get propsLabel() {
    return this.entireProps.label
  }

  private get propsChildren() {
    return this.entireProps.children
  }

  private get propsIsLeaf() {
    return this.entireProps.isLeaf
  }

  private get propsDisabled() {
    return this.entireProps.disabled
  }

  private get selectedLabel() {
    if (!this.value) return ''

    const props = { children: this.propsChildren }

    if (isMultipleValue(this.value)) {
      const nodes = this.value
        .map(v => treeFind(this.data, node => node[this.propsValue] === v, props))
        .filter(v => v)

      return nodes.map(node => node && node[this.propsLabel]).join(this.separator)
    } else {
      const node = treeFind(this.data, node => node[this.propsValue] === this.value, props)

      return node ? node[this.propsLabel] : ''
    }
  }
  @Watch('data')
  private watchData(data: Record[]) {
    const props = { children: this.propsChildren }

    if (isMultipleValue(this.syncedValue)) {
      const nodes = this.syncedValue
        .map(v => treeFind(data, node => node[this.propsValue] === v, props))
        .filter(v => v)
      this.syncedValue = nodes.map(node => node && node[this.propsValue])
    } else {
      const node = treeFind(data, node => node[this.propsValue] === this.syncedValue, props)

      this.syncedValue = node ? node[this.propsValue] : ''
    }
  }
  @Watch('value')
  private watchValue(value?: Value) {
    if (value) {
      if (isMultipleValue(value)) {
        this.elTree?.setCheckedKeys(value)
      } else {
        this.elTree?.setCurrentKey(value)
      }
    } else {
      this.elTree?.setCheckedKeys([])
      this.elTree?.setCurrentKey(null)
    }
  }

  /** 初始化 data 为空时，获取 value 值也为空，跳过调用 */
  @Watch('emitLeaves')
  private watchEmitLeaves() {
    if (this.data.length > 0) {
      this.syncCheckedKeys()
    }
  }

  private getTreeItemClass(data: Record) {
    return {
      'is-selected': this.multiple ? false : data[this.propsValue] === this.value,
      'is-disabled': data[this.propsDisabled]
    }
  }

  private getTreeLabel(data: AnyObj) {
    const label = data[this.propsLabel]
    const index = label.indexOf(this.searchValue)

    if (index > -1) {
      const before = label.substr(0, index)
      const after = label.substr(index + this.searchValue.length)

      return { before, after }
    }
  }

  private toggleVisible(visible?: boolean) {
    if (visible === this.visible) return

    this.visible = visible ?? !this.visible
    /**
     * 下拉框出现/隐藏时触发
     *
     * @property {boolean} visible 出现则为 true，隐藏则为 false
     */
    this.$emit('visible-change', this.visible)

    if (this.visible) {
      this.$emit('focus')
    } else {
      this.searchValue = ''
      this.$emit('blur')
    }
  }

  private clear() {
    if (this.multiple) {
      this.syncedValue = []
    } else {
      this.syncedValue = ''
    }

    this.visible = false

    /**
     * 在点击由 clearable 属性生成的清空按钮时触发
     */
    this.$emit('clear')
  }

  private handleScroll() {
    this.scrollbar?.handleScroll()
  }

  private handleInputChange(value: string) {
    this.searchValue = value
    this.elTree?.search(value)
  }

  private handleInputClick() {
    this.toggleVisible(true)
  }

  private handleNodeClick(data: Record, node: Record, component: Record) {
    const children = data[this.propsChildren]
    const value = data[this.propsValue]

    if ((children && children.length && !this.checkStrictly) || this.multiple) {
      component.handleExpandIconClick()
    } else {
      if (value !== this.value) {
        this.syncedValue = value
      }
      this.toggleVisible(false)
    }
  }

  private handleNodeCheck() {
    this.syncCheckedKeys()
  }

  private syncCheckedKeys() {
    if (!this.multiple || !this.elTree) return

    if (this.emitLeaves) {
      this.syncedValue = this.elTree.getCheckedKeys(!this.checkStrictly)
    } else {
      const checkedNodes = this.elTree.getCheckedNodes()
      let parentNodes = [...checkedNodes]
      checkedNodes.forEach(node => {
        if (node[this.propsChildren]) {
          parentNodes = parentNodes.filter(a =>
            node[this.propsChildren].every(
              (child: any) => child[this.propsValue] !== a[this.propsValue]
            )
          )
        }
      })

      this.syncedValue = parentNodes.map(node => node[this.propsValue])
    }
  }
}
