import { Request } from './request'
import bigInt from 'json-bigint'

export class V2 extends Request {
  constructor() {
    super('', { baseURL: '/api/v2' })
  }

  getCapturesSearch(data: AnyObj) {
    return this.post('/captures/search', data)
  }
  getRealtyBatchPersons(data: AnyObj) {
    return this.post('realty/batch/persons/search', data, {
      transformResponse: [
        data => {
          return bigInt.parse(data) // 把响应数据中的"大数"的值转换为一个js字符串对象
        }
      ]
    })
  }
  getPlayBack(physicalId: string, data: AnyObj) {
    return this.post(`/media/playbacks/${physicalId}`, data, { responseType: 'blob' })
  }
  currentArea(id: string, scopeId: string) {
    return this.get(`/persons/${id}/current-area?areaId=${scopeId}`)
  }
  realtimePerson(opt: AnyObj) {
    return this.get('/realtime/persons', opt)
  }
  getPersonTraceApi(personId: string, opt: AnyObj) {
    return this.post(`/persons/${personId}/trace`, opt)
  }

  personsDetect(opt: AnyObj) {
    return this.post('/persons/detect', opt)
  }
  personsCompare(opt: AnyObj) {
    return this.post('/persons/compare', opt)
  }
}

export default new V2()
