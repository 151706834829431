







import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { is } from '@/utils/helpers'

@Component
export default class FilterItem extends Vue {
  /**
   * 筛选项字段
   */
  @Prop() prop?: string
  /**
   * 标签文本
   */
  @Prop() label?: string
  /**
   * 触发筛选的事件名称
   */
  @Prop({ default: 'change' }) trigger: string | string[]

  @Inject({ default: () => () => null }) filter: (prop?: string) => void
  @Inject({ default: false }) locked: boolean

  mounted() {
    this.addTriggerEvent()
  }

  private addTriggerEvent() {
    if (this.$slots.default) {
      for (const node of this.$slots.default) {
        const instance = node.componentInstance

        if (instance) {
          if (is.str(this.trigger)) {
            instance.$on(this.trigger, () => this.handleTrigger(this.prop))
          } else {
            for (const trigger of this.trigger) {
              instance.$on(trigger, () => this.handleTrigger(this.prop))
            }
          }
        }
      }
    }
  }

  private handleTrigger(prop?: string) {
    this.filter(prop)
    this.$emit('filter')
  }
}
