type RGBA = { r: number; g: number; b: number; a: number }

const regex =
  /^(#([0-9a-f]{8}|[0-9a-f]{6}|[0-9a-f]{4}|[0-9a-f]{3})|rgba?\((-?[\d.]+%?[,\s]+){2,3}\s*[\d.]+%?\))$/i

const clamp = (min: number, max: number) => (v: number) => Math.max(min, Math.min(v, max))
const sanitize = (v: number) => (v % 1 ? Number(v.toFixed(5)) : v)

const testColorText = (text: string, type: '#' | 'rgb') => regex.test(text) && text.startsWith(type)
const parseColorText = (text: string) =>
  text
    .replace(/rgba?\(|\)/gi, '')
    .split(/,\s*/)
    .map(i => parseFloat(i))

const rgb = {
  create: (r: number, g: number, b: number, a: number): RGBA => ({ r, g, b, a }),
  template: ({ r, g, b, a }: RGBA) => `rgba(${r}, ${g}, ${b}, ${a})`,
  test: (text: string) => testColorText(text, 'rgb'),
  parse: (text: string) => {
    const [r, g, b, a = 1] = parseColorText(text)

    return rgb.create(r, g, b, a)
  },
  stringify: ({ r, g, b, a }: RGBA) => {
    const transformRgb = (v: number) => Math.round(clamp(0, 255)(v))

    return rgb.template({
      r: transformRgb(r),
      g: transformRgb(g),
      b: transformRgb(b),
      a: sanitize(clamp(0, 1)(a))
    })
  }
}

const hex = {
  test: (text: string) => regex.test(text) && text.startsWith('#'),
  parse: (text: string): RGBA => {
    text = text.replace(/#/, '')

    if (text.length === 3) text = `${text.replace(/(.)/g, '$1$1')}ff`
    if (text.length === 4) text = `${text.replace(/(.)/g, '$1$1')}`
    if (text.length === 6) text = `${text}ff`

    const hexToInt = (hex: string) => parseInt(hex, 0x10)

    return rgb.create(
      hexToInt(text.substr(0, 2)),
      hexToInt(text.substr(2, 2)),
      hexToInt(text.substr(4, 2)),
      hexToInt(text.substr(6, 2)) / 255
    )
  }
}

export const color = {
  test: (text: string) => regex.test(text),
  parse: (text: string): RGBA => {
    if (hex.test(text)) return hex.parse(text)
    if (rgb.test(text)) return rgb.parse(text)

    throw new Error('Incorrect color format')
  },
  stringify: (color: RGBA) => rgb.stringify(color)
}
