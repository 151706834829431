import { MutationTree } from 'vuex'
import { State } from './state'
import { RouteConfig } from 'vue-router'
import CustomKeycloak from '@/libs/keycloak'

export const mutationTypes = {
  SET_USER: 'setUser',
  SET_CONFIG: 'setConfig',
  SET_ROUTES: 'setRoutes',
  SET_APP: 'setApp',
  SET_DASHBOARD: 'setDashboard',
  SET_KEYCLOAK: 'setKeycloak',
  SET_DYNAMIC_ROUTES: 'setDynamicRoutes',
  SET_FEATURES: 'setFeatures',
  SET_TENANTID: 'setTenantId',
  SET_SCENE: 'setScene',
  SET_BASE_AREAS: 'setBaseAreas',
  SET_TAGS: 'setTags'
}

const mutations: MutationTree<State> = {
  [mutationTypes.SET_USER](state, user) {
    state.user = user
  },
  [mutationTypes.SET_CONFIG](state, info) {
    const rootConfig = localStorage.getItem('rootConfig')
    if (rootConfig) {
      info.root = { ...info.root, ...JSON.parse(rootConfig) }
    }
    state.config = info
  },
  [mutationTypes.SET_ROUTES](state, routes: RouteConfig[]) {
    state.routes = routes
  },
  [mutationTypes.SET_DYNAMIC_ROUTES](state, routes: string[][]) {
    state.dynamicRoutes = routes
  },
  [mutationTypes.SET_APP](state, app: Vue) {
    state.app = app
  },
  [mutationTypes.SET_DASHBOARD](state, dashboard: Vue) {
    state.dashboard = dashboard
  },
  [mutationTypes.SET_KEYCLOAK](state, keycloak: CustomKeycloak) {
    state.keycloak = keycloak
  },
  [mutationTypes.SET_BASE_AREAS](state, areas) {
    state.baseAreas = areas
  },
  [mutationTypes.SET_TAGS](state, tags) {
    state.tags = tags
  },
  [mutationTypes.SET_TENANTID](state, tenantId) {
    state.tenantId = tenantId
  },
  [mutationTypes.SET_FEATURES](state, features) {
    state.features = { ...state.features, ...features }
  },
  [mutationTypes.SET_SCENE](state, scene) {
    state.scene = scene
  }
}

export default mutations
