













import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { actionTypes } from '@/store'

type Area = { id: string; name: string; children: Area[] }

@Component
export default class AreaTreeSelect extends Vue {
  @Model('change') value: string[] | string

  @Prop({ type: String, default: 'base' }) readonly type: 'base' | 'europa'
  @Prop({ type: Array }) readonly data?: Area[]

  areaTypes = {
    base: {
      get: () => this.$store.state.baseAreas,
      fetch: () => this.$store.dispatch(actionTypes.FETCH_BASE_AREAS)
    },
    europa: {
      get: () => this.$store.state.areas.areas,
      fetch: () => this.$store.dispatch('areas/fetchAreas')
    }
  }

  get syncedValue() {
    return this.value
  }

  set syncedValue(value: string[] | string) {
    this.$emit('change', value)
  }

  get areas() {
    return this.data ?? this.areaTypes[this.type].get()
  }

  mounted() {
    if (!this.data || this.areas.length === 0) {
      this.areaTypes[this.type].fetch()
    }
  }
}
