const clickoutside = {
  bind(el: any, binding: any) {
    const handler = (e: any) => {
      if (el.contains(e.target)) {
        return false
      }

      if (binding.expression) {
        binding.value(e)
      }
    }

    el.__clickoutside__ = handler
    document.addEventListener('click', handler)
  },

  unbind(el: any) {
    document.removeEventListener('click', el.__clickoutside__)
    delete el.__clickoutside__
  }
}

export default clickoutside
