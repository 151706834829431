class Socket {
  ws: WebSocket
  url: string
  stash: Record<string, any>
  timer = 0
  _query: string
  userClose = false // 判断是否为用户主动关闭，异常关闭会触发重连
  constructor(private readonly path = '') {
    this.stash = {}
  }

  public connect(query?: Record<string, string | number | boolean>) {
    if (query) {
      this.setQuery(query)
    }
    this.init()
  }

  public close() {
    this.userClose = true
    this.timer && clearTimeout(this.timer)
    this.ws && this.ws.close()
  }

  public on(key: string, fn: Function) {
    if (this.stash[key]) {
      this.stash[key].push(fn)
    } else {
      this.stash[key] = [fn]
    }
  }

  private setQuery(query?: Record<string, string | number | boolean>) {
    let result = ''
    if (query) {
      const keys = Object.keys(query)

      for (const key of keys) {
        result.includes('?')
          ? (result += `&${key}=${query[key]}`)
          : (result = `?${key}=${query[key]}`)
      }
    }

    this._query = result
  }

  private init() {
    this.url = this.parseUrl()
    this.ws = new WebSocket(this.url)

    this.ws.addEventListener('open', event => {
      this.emit('connect', event)
      this.userClose = false
    })

    this.ws.addEventListener('error', error => {
      this.emit('error', error)
      clearTimeout(this.timer)
      this.timer = window.setTimeout(() => {
        this.emit('reconnect')
      }, 2000)
    })

    this.ws.addEventListener('message', message => {
      try {
        const data = message.data ? JSON.parse(message.data) : null
        this.emit('message', data)
      } catch (error) {
        this.emit('message', null)
      }
    })

    this.ws.addEventListener('close', () => {
      this.emit('close')
      if (this.userClose == false) {
        clearTimeout(this.timer)
        this.timer = window.setTimeout(() => {
          this.emit('reconnect')
        }, 2000)
      }
    })
  }

  private emit(key: string, value: any = '') {
    const fns = this.stash[key]

    if (fns) {
      for (const fn of fns) {
        fn(value)
      }
    }
  }

  private parseUrl() {
    let origin = location.origin

    if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
      origin = process.env.VUE_APP_BASE_URL_APP || ''
    }

    origin = origin.replace('http', 'ws')

    return `${origin}${this.path}${this._query}`
  }
}

export default new Socket('/api/v1/messages/')
