
























import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

type Option = AnyObj
type Props = { label?: string; value?: string }

/**
 * 基于 el-select，新增特性选项数据懒加载，以及远程搜索结果懒加载
 */
@Component({
  directives: {
    'el-select-infinite-scroll': {
      bind(el, binding) {
        // TODO: Custom Attributes
        const props = {
          delay: { type: Number, default: 200 },
          distance: { type: Number, default: 0 },
          immediate: { type: Boolean, default: true }
        }

        const {
          delay: { default: delay },
          distance: { default: distance }
        } = props

        const dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')

        if (dom) {
          let timer: NodeJS.Timeout | null = null

          dom.addEventListener('scroll', () => {
            if (!timer) {
              timer = setTimeout(() => {
                timer = null
                const { scrollHeight, scrollTop, clientHeight } = dom

                if (scrollHeight - scrollTop - clientHeight <= distance) {
                  if (binding.value) binding.value()
                }
              }, delay)
            }
          })
        }
      }
    }
  }
})
export default class ElSelectPlus extends Vue {
  /**
   * 可选项数据源，键名可通过 Props 属性配置，支持 .sync 修饰符
   */
  @PropSync('options', { type: Array, default: () => [] }) syncedOptions: Option[]
  /**
   * 配置选项
   */
  @Prop({ type: Object, default: () => ({}) }) props: Props
  /**
   * 是否懒加载选项数据，需与 load 方法结合使用
   */
  @Prop({ type: Boolean, default: false }) readonly lazy: boolean
  /**
   * 加载选项数据的方法，仅当 lazy 属性为true 时生效
   */
  @Prop({ type: Function }) readonly load?: (
    options: Option[],
    filterText: string
  ) => Promise<Option[]>
  /**
   * 是否为远程搜索
   */
  @Prop({ type: Boolean, default: false }) readonly remote: boolean
  /**
   * 远程搜索方法
   */
  @Prop({ type: Function }) readonly remoteMethod?: (value: string) => Promise<Option[]>
  /**
   * 搜索关键词输入的去抖延迟，毫秒
   */
  @Prop({ type: Number, default: 300 }) readonly debounce: number
  /**
   * 自定义搜索逻辑
   */
  @Prop({ type: Function }) readonly filterMethod?: Function

  filterText = ''
  filtering = false
  cachedOptions: Option[] = []

  get entireProps() {
    return { label: 'label', value: 'value', ...this.props }
  }

  async handleInfiniteScroll() {
    if (this.lazy) {
      this.loadOptions()
    }
  }

  async filterRemote(value: string) {
    if (this.remote) {
      if (this.timer) clearTimeout(this.timer)

      this.timer = setTimeout(async () => {
        this.filterText = value

        if (this.remoteMethod) {
          if (this.filterText) {
            if (!this.filtering) {
              this.filtering = true
              this.cachedOptions = this.syncedOptions
            }

            const options = await this.remoteMethod(this.filterText)

            this.syncedOptions = options
          } else if (this.filtering) {
            this.filtering = false
            this.syncedOptions = this.cachedOptions
          }
        } else {
          throw 'property remoteMethod is required'
        }
      }, this.debounce)
    }
  }

  async loadOptions() {
    if (this.load) {
      const options = await this.load(this.options, this.filterText)
      this.syncedOptions = [...this.syncedOptions, ...options]
    } else {
      throw 'property load is required'
    }
  }

  handleVisibleChange(visible: boolean) {
    if (!visible) {
      if (this.filtering && this.cachedOptions.length) {
        this.filtering = false
        this.syncedOptions = this.cachedOptions
      }

      this.filterText = ''
      this.cachedOptions = []
    }
  }
}
