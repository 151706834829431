import { Request } from './request'
import store from '@/store'

export class Base extends Request {
  constructor() {
    super('/base')
  }

  getMapse() {
    return this.get('/maps')
  }

  getTenantConfig() {
    return this.get('/configs/tenant')
  }

  getAreas(limit?: number, offset?: number) {
    return this.get('/areas', { params: { limit, offset } })
  }
  getZoneAreas(zoneId: string) {
    return this.get(`/zones/${zoneId}/areas`)
  }

  getAreasTree(zoneId: string) {
    return this.get(`/zones/${zoneId}?form=tree`)
  }

  getAreaList() {
    return this.get('/areas')
  }

  getAreaCreate(opt: AnyObj) {
    return this.post('/areas', opt)
  }

  getEditAreaList(id: string, opt: AnyObj) {
    return this.put(`/areas/${id}`, opt)
  }

  getAreaDelete(id: string) {
    return this.delete(`/areas/${id}`)
  }

  getCreateMonitor(areaId: string, opt: AnyObj) {
    return this.post(`/areas/${areaId}/monitor`, opt)
  }

  getMonitor(areaId: string) {
    return this.get(`/areas/${areaId}/monitor`)
  }

  getDeleteMonitor(areaId: string, opt: AnyObj) {
    return this.delete(`/areas/${areaId}/monitor`, { data: opt })
  }

  getEditDevice(id: string, opt: AnyObj) {
    return this.put(`/devices/${id}`, opt)
  }

  getDevicesAreaPath(deviceId: string) {
    return this.get(`/devices/${deviceId}/path`)
  }

  getDeviceTags(id: string) {
    return this.get(`/devices/${id}/tags`)
  }
  postDeviceTags(id: string, opt: AnyObj) {
    return this.post(`/devices/${id}/tags`, opt)
  }
  bindDevice(deviceId: string, areaId: string) {
    return this.put(`/devices/${deviceId}/bind/areas/${areaId}`)
  }
  unbindDevice(id: string) {
    return this.put(`/devices/${id}/unbind`)
  }
  getDeviceTypes() {
    return this.get(`/devices/types`)
  }
  getDevicesSearch(opt: AnyObj) {
    return this.post('/devices/search', opt)
  }
  fetchDevicesDetailSearch(opt: AnyObj) {
    return this.post('/devices/detail/search', opt)
  }
  getDeviceInfo(id: string) {
    return this.get(`/devices/${id}`)
  }
  deleteDevice(id: string) {
    return this.delete(`/devices/${id}`)
  }
  addDevice(opt: AnyObj) {
    return this.post('/devices', opt)
  }

  getTagDevices(id: string) {
    return this.get(`/tags/${id}/devices`)
  }
  postTagDevices(id: string, opt: AnyObj) {
    return this.post(`/tags/${id}/devices`, opt)
  }
  areaTags(id: string, opt: AnyObj) {
    return this.post(`/areas/${id}/tags`, opt)
  }
  getMapList(limit: number, offset: number) {
    return this.get(`/maps?limit=${limit}&&offset=${offset}`)
  }

  getEidtMap(mapId: string, opt: AnyObj) {
    return this.put(`/maps/${mapId}`, opt)
  }

  getPersonSingle(personId: string) {
    return this.get(`/persons/${personId}`)
  }

  getTagsDelete(personId: string, tags: string) {
    return this.delete(`/persons/${personId}/tags/${tags}`)
  }

  getTagsAdd(personId: string, opt: AnyObj) {
    return this.post(`/persons/${personId}/tags`, opt)
  }

  getPersonsCreate(opt: AnyObj) {
    return this.post('/persons', opt)
  }

  getPersonsEdit(id: string, opt: AnyObj) {
    return this.put(`/persons/${id}`, opt)
  }

  getPersonsDelete(id: string) {
    return this.delete(`/persons/${id}`)
  }

  addPersonImage(id: string, opt: AnyObj) {
    return this.post(`/persons/${id}/images`, opt)
  }

  getPersonsDeletePhoto(id: string, imageId: string) {
    return this.delete(`/persons/${id}/images/${imageId}`)
  }

  getPersonsUpdatePhoto(id: string, imageId: string, opt: AnyObj) {
    return this.put(`/persons/${id}/images/${imageId}`, opt)
  }

  getPersonsHistoryPhotoList(id: string) {
    return this.get(`/persons/${id}/images`)
  }

  getPersonTripsCount(personId: string) {
    return this.get(`/persons/${personId}/tripsCount`)
  }

  getUpdateTag(personId: string, opt: AnyObj) {
    return this.put(`/persons/${personId}/tags`, opt)
  }

  getPersonsSimilar(opt: AnyObj) {
    return this.post('/persons/similar', opt, { timeout: 60000 })
  }

  getInsightFlow(opt: AnyObj) {
    return this.post('/insights/flow', opt)
  }
  getStatisticsAreas(opt: AnyObj) {
    return this.post('/statistics/areas', opt)
  }
  getResidenceTime(opt: AnyObj) {
    return this.post('/statistics/stay/sum/areas', opt)
  }
  updateMapInfo(id: string, opt: AnyObj) {
    return this.put(`/maps/${id}`, opt)
  }

  getRadarData(opt: string) {
    return this.get(`/statistics/radar/areas/${opt}`)
  }
  getStatistics(opt: AnyObj) {
    return this.post('/statistics/areas', opt)
  }

  getStatisticsSum(opt: AnyObj) {
    return this.post('/statistics/sum/areas', opt)
  }

  mostFrequencyArea(personId: string, opt: AnyObj) {
    return this.get(`/persons/${personId}/most-frequency-area`, opt)
  }

  getQualityStatistic(data: AnyObj) {
    return this.post('/statistics/quality', data)
  }

  getPassageCounts(options: {
    span?: 'hour' | 'day'
    startTime?: string
    endTime?: string
    areaId: string
  }) {
    return this.post('/passages/statistics/count', options)
  }

  getPassageStatistics(options: {
    span?: 'hour' | 'day'
    areaIds?: string[]
    tagIds?: string[]
    tagTypes?: ('white' | 'black' | 'vip' | 'strange')[]
    type?: number
    startTime?: string
    endTime?: string
  }) {
    return this.post('/passages/statistics/statistics', options)
  }

  getRealtyPlaybacks(token: string) {
    return this.get('/realty/playbacks', { params: { token }, responseType: 'blob' })
  }
  getPersonPlaybacks(id: string, { time, areaId }: { time: string; areaId: string }) {
    return this.post(`/persons/${id}/playbacks`, { time, areaId }, { responseType: 'blob' })
  }

  getRiskControlPlayback(data: AnyObj) {
    return this.post('/risk-control/playback/search', data)
  }
  getPersonsCaptures(personId: string, data: AnyObj) {
    return this.post(`/persons/${personId}/captures`, data)
  }

  getVehicles(data: AnyObj) {
    return this.post('/vehicles/person/search', data)
  }

  createCustomerReport(data: AnyObj) {
    return this.post('/realty/appointments', data)
  }

  deleteCustomerReport(id: string) {
    return this.delete(`/realty/appointments/${id}`)
  }

  searchCustomerReports(data: AnyObj) {
    return this.post('/realty/appointments/search', data)
  }

  verificationsSearch(opt: AnyObj) {
    return this.post('/realty/verifications/detail/search', opt)
  }

  matchVerificationReport(id: string, reportId: string) {
    return this.put(`/realty/verifications/${id}/matching/${reportId}`)
  }

  getVerification(id: string) {
    return this.get(`/realty/verifications/${id}`)
  }

  updateVerification(id: string, data: AnyObj) {
    return this.put(`/realty/verifications/${id}`, data)
  }

  deleteVerification(id: string) {
    return this.delete(`/realty/verifications/${id}`)
  }

  getDevicePreview(id: string) {
    return this.get(`/devices/${id}/preview`, { responseType: 'blob' })
  }

  getConfigCube() {
    return this.get(`/configs/cube`)
  }

  submitConfigCube(opt: AnyObj) {
    return this.put('/configs/cube', opt)
  }

  getAreaTree() {
    return this.get(`/area/tree`)
  }

  getRootAreaTree() {
    return this.get('/area/tree', { timeout: 10000, headers: { 'no-root': true } })
  }

  getIndexCount(opt: AnyObj) {
    return this.post('/apps/index/count', opt)
  }

  getHeating() {
    return this.get('/apps/index/realtime')
  }

  getCities(city: string, subDistrict = 1) {
    return this.get('/apps/utils/cities', { params: { city, subDistrict } })
  }

  getWeather(city: string) {
    return this.get('/apps/utils/weather', { params: { city } })
  }

  formatImage(url: string) {
    return this.post('/apps/utils/image/format', { url })
  }

  getStatisticsDayCount(opt: AnyObj) {
    return this.post('/apps/statistics/statistics/day-count', opt)
  }

  statisticExport(opt: AnyObj) {
    return this.post('/apps/statistics/statistics/day-count/export', opt, {
      responseType: 'blob',
      timeout: 300000
    })
  }

  areasInsights(personId: string, opt?: AnyObj) {
    return this.get(`/persons/${personId}/area-intimacy/detail`, opt)
  }

  getPersonInsight(personId: string) {
    return this.get(`/persons/${personId}/intimacy/detail`)
  }

  getTripsList(areaId: string, opt: AnyObj) {
    return this.post(`/areas/${areaId}/day-access/detail`, opt)
  }

  dayAccessExport(id: string, opt: AnyObj) {
    return this.post(`/areas/${id}/day-access/detail/export`, opt, { timeout: 300000 })
  }

  getPassagesSearch(opt: AnyObj) {
    return this.post('/passages/detail/search', opt)
  }

  getPassagesExport(opt: AnyObj) {
    return this.post('/passages/detail/export', opt, { timeout: 300000 })
  }

  getPersonSearch(opt: AnyObj) {
    return this.post('/day-access/times/persons/filter/detail', opt)
  }

  personsFindExport(opt: AnyObj) {
    return this.post('/day-access/times/persons/filter/detail/export', opt, { timeout: 300000 })
  }

  getAllPersonsList(opt: AnyObj) {
    return this.post('/persons/search/detail', opt)
  }

  personsExport(opt: AnyObj) {
    return this.post('/persons/search/detail/export', opt, { timeout: 300000 })
  }

  getPersonInfo(personId: string) {
    return this.get(`/persons/${personId}/detail`)
  }

  getPersonCalendarAccess(personId: string, opt: AnyObj) {
    return this.get(`/persons/${personId}/access/calendar?date=${opt.date}`)
  }

  indexConfig() {
    return this.get('/apps/index/config')
  }

  uploadImage(formData: any) {
    return this.post('/apps/utils/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  getTagsWithAccount(accountId: string) {
    return this.get(`/accounts/${accountId}/tags`)
  }

  getContrastList(opt: AnyObj) {
    return this.post('/tags/search', opt)
  }

  getContrastCreate(opt: AnyObj) {
    return this.post('/tags', opt)
  }

  getAllTags() {
    return this.get('/tags/all')
  }

  getEditContrast(id: string, opt: AnyObj) {
    return this.put(`/tags/${id}`, opt)
  }

  getContrastDelete(id: string) {
    return this.delete(`/tags/${id}`)
  }

  getAppointTag(tagId: string) {
    return this.get(`/tags/${tagId}/persons`)
  }
  getSelectTag(tagId: string) {
    return this.get(`/tags/${tagId}`)
  }

  getPersonsList(id: string, limit: number, offset: number) {
    return this.get(`/tags/${id}/persons/?limit=${limit}&&offset=${offset}`)
  }

  // Request local service
  getPersonInfoCollection(data: AnyObj) {
    return this.post('/person/info/collections/search', data, { baseURL: store.getters.baseURL })
  }

  updatePersonInfoCollection(id: string, data: AnyObj) {
    return this.put(`/person/info/collections/${id}`, data, { baseURL: store.getters.baseURL })
  }

  deletePersonInfoCollection(id: string) {
    return this.delete(`/person/info/collections/${id}`, { baseURL: store.getters.baseURL })
  }
}

export default new Base()
