// @ts-nocheck
import Vue from 'vue'
import {
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Autocomplete,
  InputNumber,
  Button,
  Table,
  TableColumn,
  Form,
  FormItem,
  Loading,
  MessageBox,
  Dialog,
  Dropdown,
  DropdownMenu,
  Card,
  DropdownItem,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  Select,
  Option,
  Tag,
  Radio,
  RadioGroup,
  RadioButton,
  DatePicker,
  TimeSelect,
  TimePicker,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Tabs,
  TabPane,
  Drawer,
  Switch,
  Popover,
  Scrollbar,
  InfiniteScroll,
  row,
  col,
  slider,
  drawer
} from 'element-ui'
import ElTreeSelect from '@/components/common/ElTreeSelect'
import Tree from '@/components/common/ElTreePlus'
import Cascader from '@/components/element-ui/cascader'
import CascaderPanel from '@/components/element-ui/cascader-panel'

Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(Autocomplete)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tooltip)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tag)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Cascader)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Drawer)
Vue.use(CascaderPanel)
Vue.use(Switch)
Vue.use(Popover)
Vue.use(Scrollbar)
Vue.use(InfiniteScroll)
Vue.use(row)
Vue.use(col)
Vue.use(slider)
Vue.use(Card)
Vue.use(drawer)
Vue.use(Loading.directive)
Vue.use(ElTreeSelect)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
// Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$message = Message
