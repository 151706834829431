



















import { actionTypes } from '@/store'
import { Component, Model, Prop, Vue } from 'vue-property-decorator'

type Tag = { id: string; name: string; type: string }
type Option = { label: string; value: string; children?: Option[]; disabled?: boolean }
type Value = { tagTypes?: string[]; tagIds: string[]; strange: boolean }
type PartialValue = { [K in keyof Value]?: Value[K] }

const identityMap: Record<string, string> = {
  white: '白名单',
  vip: 'VIP',
  black: '黑名单',
  strange: '陌生人'
}

@Component
export default class IdentityCascader extends Vue {
  @Model('change', { default: () => ({}) }) value: PartialValue | null

  @Prop({ type: Array, default: () => ({}) }) readonly data!: Tag[]
  @Prop({ type: Boolean, default: true }) readonly strange!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  cascaderProps = { multiple: true, scrollLoad: true }
  visible = false

  get cascaderValue() {
    if (!this.value) return []

    const value: string[][] = []

    this.value.tagIds?.forEach(tagId => {
      const tags = this.data.filter(({ id }) => id === tagId)

      value.push(...tags.map(tag => [tag.type, tag.id]))
    })

    this.value.tagTypes?.forEach(tagType => {
      const tags = this.data.filter(({ type }) => type === tagType)

      value.push(...tags.map(tag => [tag.type, tag.id]))
    })

    if (this.value.strange) {
      value.push(['strange'])
    }

    return value
  }

  get options(): Option[] {
    const options: Option[] = [
      { label: 'VIP', value: 'vip', children: [] },
      { label: '白名单', value: 'white', children: [] },
      { label: '黑名单', value: 'black', children: [] }
    ]

    for (const item of this.data) {
      const idx = options.findIndex(ele => ele.value === item.type)
      if (idx !== -1) {
        options[idx].children?.push({ label: item.name, value: item.id })
      } else {
        options.push({
          label: identityMap[item.type],
          value: item.type,
          children: [{ label: item.name, value: item.id }]
        })
      }
    }

    for (const value of options) {
      if (value.children?.length == 0) {
        value.disabled = true
      }
    }

    if (this.strange) {
      options.push({ label: identityMap.strange, value: 'strange' })
    }

    return options
  }

  mounted() {
    this.$store.dispatch(actionTypes.FETCH_TAGS)
  }

  handleChange(cascaderValue: string[][]) {
    if (cascaderValue.length === 0) {
      this.$emit('change', null)
      this.$emit('clear')

      return
    }

    const value: Value = { tagTypes: [], tagIds: [], strange: false }

    this.options.forEach(option => {
      const checkedValue = cascaderValue.filter(([type, id]) => type === option.value && id)
      value.tagIds.push(...checkedValue.map(([, id]) => id))
    })

    value.strange = cascaderValue.some(([type]) => type === 'strange')
    if (value.tagTypes?.length == 0) {
      delete value.tagTypes
    }

    this.$emit('change', value)
  }

  handleVisibleChange(visible: boolean) {
    this.visible = visible

    if (visible) {
      this.$emit('focus')
    } else {
      this.$emit('blur')
    }
  }

  handleRemoveTag(value: string[]) {
    this.$emit('remove-tag', value)
  }
}
