import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { axios } from '../axios'

class AsyncQueue {
  private cache: Record<string, Promise<any>[]> = {}

  async wait<R>(key: string, p: Promise<R>) {
    if (!this.cache[key]) this.cache[key] = []

    const priorityQueue = [...this.cache[key]]

    this.cache[key].push(p)

    await Promise.all(priorityQueue)
    const v = await p

    this.cache[key].splice(this.cache[key].indexOf(p), 1)

    return v
  }
}

interface RequestInstance {
  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>
  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>
  options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>
}

export class Request implements RequestInstance {
  private prefix: string
  protected axios = axios
  private config?: AxiosRequestConfig
  private queue = new AsyncQueue()

  constructor(prefix: string, config?: AxiosRequestConfig) {
    this.prefix = prefix
    this.config = config
  }

  private join(url: string) {
    return this.prefix + url
  }

  private merge(config?: AxiosRequestConfig) {
    return { ...this.config, ...config }
  }

  private key(method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH', url: string) {
    return method + ':' + url
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return this.queue.wait(
      this.key('GET', url),
      axios.get<T, R>(this.join(url), this.merge(config))
    )
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.queue.wait(
      this.key('POST', url),
      axios.post<T, R>(this.join(url), data, this.merge(config))
    )
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.queue.wait(
      this.key('PUT', url),
      axios.put<T, R>(this.join(url), data, this.merge(config))
    )
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return this.queue.wait(
      this.key('DELETE', url),
      axios.delete<T, R>(this.join(url), this.merge(config))
    )
  }

  options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return this.queue.wait(
      this.key('OPTIONS', url),
      axios.options<T, R>(this.join(url), this.merge(config))
    )
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.queue.wait(
      this.key('PATCH', url),
      axios.patch<T, R>(this.join(url), data, this.merge(config))
    )
  }
}
