import { MutationTree } from 'vuex'

type State = {
  isEditMode: boolean
}

const state: State = {
  isEditMode: false
}

const mutations: MutationTree<State> = {
  changeMode(state, val: boolean) {
    state.isEditMode = val
  }
}

export default { namespaced: true, state, mutations }
