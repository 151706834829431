import { ActionTree } from 'vuex'
import api from '@/api'
import { mutationTypes } from './mutations'
import { State } from './state'
import { actionTypes as realtimeActionTypes } from './realTime'
import wm from '@/utils/watermark'
import { getAllTags } from '@/utils/getTags'
import ws from '@/libs/socket-web'

class AsyncQueue {
  private cache: Map<string, Promise<any>> = new Map()

  async wait<R>(key: string, f: () => Promise<R>) {
    const cache = this.cache.get(key)

    if (cache) {
      return cache
    }

    const p = f().then(r => {
      this.cache.delete(key)

      return r
    })

    this.cache.set(key, p)

    return p
  }
}

const queue = new AsyncQueue()

export const actionTypes = {
  FETCH_PROFILE: 'fetchProfile',
  FETCH_DYNAMIC_ROUTES: 'fetchDynamicRoutes',
  FETCH_BASE_AREAS: 'fetchAreas',
  CONFIG_INFO: 'configInfo',
  FETCH_TAGS: 'fetchTags',
  CLEAR: 'clear'
}

const actions: ActionTree<State, State> = {
  async [actionTypes.CONFIG_INFO]({ commit }) {
    const { data } = await api.base.indexConfig()

    if (data && data.data) {
      commit(mutationTypes.SET_CONFIG, data.data)
      return data.data
    } else {
      return {}
    }
  },

  async [actionTypes.FETCH_BASE_AREAS]({ state, commit }, refresh?: boolean) {
    if (state.baseAreas.length === 0 || refresh) {
      const { data } = await queue.wait(actionTypes.FETCH_BASE_AREAS, () => api.base.getAreaTree())

      if (data.code === 0 && data.data) {
        commit(mutationTypes.SET_BASE_AREAS, [data.data])
        return [data.data]
      }
    }

    return state.baseAreas
  },

  async [actionTypes.FETCH_TAGS]({ state, commit }, refresh?: boolean) {
    if (state.tags.length === 0 || refresh) {
      const tags = await queue.wait(actionTypes.FETCH_TAGS, getAllTags)

      commit(mutationTypes.SET_TAGS, tags)
      return tags
    } else {
      return state.tags
    }
  },

  async [actionTypes.CLEAR]({ dispatch, commit }) {
    if (ws) ws.close()
    wm.clear()
    dispatch(realtimeActionTypes.ACT_BASE_REALTIME_END)
    commit(mutationTypes.SET_USER, {})
  }
}

export default actions
