export default {
  colorPrimary: '#1890ff',
  colorPrimaryLight: '#1890ff',
  colorDisabled: '#f5f5f5',
  textColorPrimary: '#000000',
  textColorRegular: 'rgba(0, 0, 0, 0.85)',
  textColorSecondary: 'rgba(0, 0, 0, 0.45)',
  textColorPlaceholder: 'rgba(0, 0, 0, 0.3)',
  textColorHeader: '#ffffff',
  shadowColorBase: 'rgba(24, 32, 37, 0.06)',
  borderColorBase: '#e1e1e1',
  backgroundColorBase: '#ffffff',
  backgroundColorBaseDark: '#f5f5f5',
  backgroundColorBaseDarker: '#e1e1e1',
  backgroundColorBaseActive: '#cdedfd',
  backgroundColorMenu: '#ffffff',
  backgroundColorSide: '#ffffff',
  backgroundColorMenuActive: '#cdedfd',
  backgroundColorPopper: '#ffffff',
  backgroundColorInput: '#ffffff',
  backgroundColorDialog: '#ffffff',
  backgroundColorLoading: 'rgba(255, 255, 255, 0.9)',
  backgroundBody: '#f5f5f5',
  backgroundBodyColor: '#f5f5f5',
  backgroundHeader: `#247fff url('${require('@/assets/images/dashboard/header-bg.png')}')`,
  textColorDatePickerDisabled: '#c0c4cc',
  backgroundColorPickerDisabled: '#f5f7fa',
  textColorTitle: '#606266',
  textColorActive: '#f2f6fc',
  textColorChecked: '#303133'
}
