import Vue from 'vue'
import VueRouter from 'vue-router'
import store, { mutationTypes } from '@/store'
import message from '@/components/common/Message'
import wm from '@/utils/watermark'
import { monitor } from '@/utils/fundebug'
import moment from 'moment'
import { filterRoutes } from '@/router/routes'

Vue.use(VueRouter)

const initRouter = (path?: RoutePath) => {
  const routes = filterRoutes(path)

  const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location: any) {
    return (originalPush.call(this, location) as any).catch((err: any) => err)
  }
  const router = new VueRouter({ mode: 'history', routes })

  store.commit(mutationTypes.SET_ROUTES, routes)

  const addMonitor = (user: AnyObj) => {
    const apikey = process.env.VUE_APP_DEBUG_STAR
    const username = user?.username || ''
    monitor(apikey, username)
  }

  // #3374
  const hiddenOpenedPopper = () => {
    const poppers = document.body.querySelectorAll<HTMLElement>('.el-popper')

    for (const popper of poppers) {
      popper.style.display = 'none'
    }
  }

  router.beforeEach(async (to, from, next) => {
    message.closeAll()
    const { user } = store.state

    addMonitor(user)

    if (to.meta?.noAuth) {
      if (user) return next('/')
    } else {
      if (user) {
        const { keycloak } = store.state

        if (user.watermark) {
          let txt = ''

          if (user.mobile) txt = user.mobile.replace('+86', '')
          else if (user.username) txt = user.username

          const authTime = keycloak?.kcOptions?.authTime
          if (authTime) {
            const time = moment(authTime)
            const year = time.format('YYYY年M月D日')
            const hour = time.format('H时m分s秒')
            txt = txt + '于//' + year + '//' + hour + '登录'
          }

          wm.setOptions({ txt })

          if (to.path !== '/cube') wm.render()
          else wm.clear()
        } else {
          wm.clear()
        }
      }

      hiddenOpenedPopper()

      if (to.meta?.auth && !to.meta.auth(store.state)) return next('/notfound')
    }

    next()
  })
  return router
}

export default initRouter
