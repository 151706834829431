import { Request } from './request'
export class Mind extends Request {
  constructor() {
    super('/base')
  }

  searchAccount(opt?: AnyObj) {
    return this.get(`/accounts`, opt)
  }

  addAccount(data: AnyObj) {
    return this.post('/accounts', data)
  }

  removeAccount(id: string) {
    return this.delete(`/accounts/${id}`)
  }

  updateAccount(id: string, data: AnyObj) {
    return this.put(`/accounts/${id}`, data)
  }

  resetPassword(id: string) {
    return this.post(`/accounts/${id}/reset-password`)
  }

  bindTagAccount(data: { bulk: { tagId: string; accountId: string; authority: number }[] }) {
    return this.post('/tag-account', data)
  }

  unbindTagAccount(data: { bulk: { tagId: string; accountId: string }[] }) {
    return this.delete('/tag-account', { data })
  }

  getAccountsWithTag(tagId: string) {
    return this.get(`/tags/${tagId}/accounts`)
  }
}

export default new Mind()
