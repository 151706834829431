





















import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator'
import { Moment } from 'moment'
import { DatePickerOptions } from 'element-ui/types/date-picker'

type DateInstance = Date | Moment

// TODO: date value formatter
@Component
export default class DatePicker extends Vue {
  @Model('change', { type: Array, default: () => [] }) value: DateInstance[]

  @Prop({ type: String, default: 'daterange' }) type: string
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: Object }) pickerOptions?: DatePickerOptions
  @Prop({
    type: Array,
    default: () => [
      { label: '今天', value: 1 },
      { label: '近7天', value: 7 },
      { label: '近30天', value: 30 }
    ]
  })
  shortcuts: { label: string; value: number }[]

  private shortcutValue = -1

  private get syncedValue() {
    return this.value
  }

  private set syncedValue(value: DateInstance[]) {
    this.$emit('change', value)
  }

  private get syncedPickerOptions() {
    const options = {
      disabledDate: (d: Date) => this.$moment().isBefore(d, 'day')
    }
    return { ...options, ...this.pickerOptions }
  }

  @Watch('value')
  private watchValue(value: DateInstance[]) {
    this.syncShortcutValue(value)
  }

  private syncShortcutValue([startDate, endDate]: DateInstance[]) {
    if (endDate && this.$moment(endDate).isSame(this.$moment(), 'day')) {
      this.shortcutValue = this.$moment(endDate).diff(startDate, 'day') + 1
    } else {
      this.shortcutValue = -1
    }
  }

  private handleDateChange(value: DateInstance[]) {
    if (value) {
      this.syncedValue = value
    } else {
      this.syncedValue = []
    }
  }

  private handleDateRadioChange(value: number) {
    const endDate = this.$moment().endOf('day')
    const startDate = this.$moment(endDate)
      .subtract(value - 1, 'day')
      .startOf('day')

    this.syncedValue = [startDate, endDate]
  }

  private mounted() {
    this.syncShortcutValue(this.value)
  }
}
