import { Message } from 'element-ui'

import { ElMessageOptions, ElMessageComponent } from 'element-ui/types/message'
import { CreateElement } from 'vue'

type MessageOptions = ElMessageOptions & {
  message: string
  onClick?: () => void
  clickButton?: string
  createElement?: CreateElement
}
type MessageFunction = (options: MessageOptions) => ElMessageComponent

/**
 * 基于 element-ui Message 的二次封装
 * @param {Object} options 参照 element-ui Message 组件 options
 * @param {Function} options.onClick 可选 组件点击事件函数 生效时点击组件可触发点击事件
 * @param {string} options.clickButton options.onClick 生效时可选 点击按钮的文本 生效时会使用按钮触发点击事件
 * @param {Function} options.createElement options.onClick 生效时必选 vm.$createElement
 * @returns Message
 */
const message: MessageFunction = options => {
  const defaultOptions = { offset: 49, duration: 5 * 1000 }
  const { onClick, clickButton, message, customClass, createElement } = options

  if (onClick && createElement) {
    const h = createElement

    return Message({
      ...defaultOptions,
      ...options,
      message: h(
        'div',
        {
          class: 'el-message__content',
          on: {
            click: () => {
              if (onClick && !clickButton) onClick()
            }
          }
        },
        [h('i', { class: 'el-message__icon el-icon-info' }), h('span', {}, message)].concat(
          clickButton
            ? [
                h(
                  'span',
                  {
                    class: 'el-message__button',
                    on: {
                      click: () => {
                        if (onClick) onClick()
                      }
                    }
                  },
                  clickButton
                )
              ]
            : []
        )
      ),
      customClass: `${customClass} is-clickable ${
        clickButton ? 'is-click-button' : 'is-click-message'
      }`
    })
  }

  return Message({
    ...defaultOptions,
    ...options
  })
}

export default Object.assign(message, Message)
