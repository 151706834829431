export default {
  colorPrimary: '#15a87f',
  colorPrimaryLight: '#13e6aa',
  colorDisabled: '#5fa692',
  textColorPrimary: '#999999',
  textColorRegular: '#dbdce7',
  textColorSecondary: '#97bccd',
  textColorPlaceholder: '#7c9099',
  textColorHeader: '#dbdce7',
  shadowColorBase: 'rgba(24, 32, 37, 0.27)',
  borderColorBase: '#495c65',
  backgroundColorBase: 'rgba(25, 40, 48, 0.8)',
  backgroundColorBaseDark: '#2d3d44',
  backgroundColorBaseDarker: '#182025',
  backgroundColorBaseActive: 'rgba(19, 230, 170, 0.2)',
  backgroundColorMenu: 'rgba(24, 32, 37, 0.79)',
  backgroundColorSide: 'rgb(24, 32, 37)',
  backgroundColorMenuActive: '#263339',
  backgroundColorPopper: '#182025',
  backgroundColorInput: '#263339',
  backgroundColorDialog: '#2d3d44',
  backgroundColorLoading: 'rgba(0, 0, 0, 0.3)',
  backgroundBody: `url(${require('@/assets/images/dashboard/home-bg.png')})`,
  backgroundBodyColor: '#1a1b1d',
  backgroundHeader: '#263339',
  textColorDatePickerDisabled: 'rgba(255, 255, 255, 0.3)',
  backgroundColorPickerDisabled: 'rgba(38, 51, 57, 0.5)',
  textColorTitle: '#97bccd',
  textColorActive: '#263339',
  textColorChecked: '#ffffff'
}
