import CustomKeycloak, { LoginType } from '@/libs/keycloak'
import store, { actionTypes, mutationTypes } from '@/store'
import VueRouter from 'vue-router'
import jwt from '@/libs/jwt'
import { api } from '@/api'
import initRouter from '@/router'
import { normalizationRoutes } from './router/role'

const getQueries = (str: string) => {
  const queries: { [key: string]: string } = {}
  str
    .substring(1)
    .split('&')
    .forEach(v => {
      const index = v.indexOf('=')
      if (index > 0 && index < v.length) {
        const key = v.substring(0, index)
        const value = v.substring(index + 1, v.length)
        queries[key] = value
      }
    })

  return queries
}

const tokenParse = (str: string) => JSON.parse(decodeURIComponent(escape(atob(str))))

const tokenToUserInfo = (token: string) => {
  const tokenData = tokenParse(token)
  const accessToken = tokenData.access_token
  if (accessToken) {
    return jwt(accessToken)
  }
}

const setUserInfo = (customKc: CustomKeycloak) => {
  const userInfo: any = customKc.kcOptions.userInfo
  if (userInfo) {
    store.commit(mutationTypes.SET_USER, { ...userInfo, scope: userInfo.root })
  }
}

const setAppTitle = (title = '智能商业认知系统') => {
  document.title = title
}

// token登录从token中获取role
const roles = [
  'star-admin',
  'passage-admin',
  'realty-admin',
  'country-admin',
  'country-user',
  'country-readonly'
]
let typeAccount = ''
let roleAccount = ''
const setTenantAndInitRouter = async () => {
  const { data }: any = await api.base.getTenantConfig()
  const userConfigs = await store.dispatch(actionTypes.CONFIG_INFO)
  const tenantId = data.data?.id
  const features = data.data?.features
  const scene = data.data?.scene
  const title = features?.title
  const path: RoutePath[] =
    normalizationRoutes(
      typeAccount === 'password' ? userConfigs.role : roleAccount,
      features?.path
    ) || []
  store.commit(mutationTypes.SET_FEATURES, features)
  store.commit(mutationTypes.SET_TENANTID, tenantId)
  store.commit(mutationTypes.SET_SCENE, scene)

  setAppTitle(title)

  return initRouter(...path)
}
const compare = (arr1: any, arr2: any) => {
  return arr1.filter((item: any) => {
    return arr2.includes(item)
  })
}

const login = async (): Promise<{ isLogin: boolean; router?: VueRouter }> => {
  const url = process.env.VUE_APP_BASE_URL_APP || ''
  const option = {
    realm: 'star',
    clientId: 'star-console',
    url: url + '/auth/'
  }

  const queries = getQueries(location.hash)
  const curMindToken = getQueries(decodeURIComponent(location.search))

  const curLegacyToken = queries.legacy_token

  if (curLegacyToken) {
    const userInfo = tokenToUserInfo(curLegacyToken)
    const username = localStorage.getItem('currentAccount')
    if (userInfo.username !== username) {
      localStorage.removeItem('keycloak')
      localStorage.removeItem('auth-time')
      localStorage.setItem('legacy-token', curLegacyToken)
    }
  }

  if (curMindToken.accessToken) {
    const userInfo = jwt(curMindToken.accessToken)
    const username = localStorage.getItem('currentAccount')
    if (userInfo.username !== username) {
      localStorage.removeItem('keycloak')
      localStorage.removeItem('auth-time')
      localStorage.setItem('legacy-token', JSON.stringify(curMindToken))
    }
  }

  const mindToken = curMindToken.accessToken
    ? getQueries(decodeURIComponent(location.search))
    : JSON.parse(localStorage.getItem('legacy-token') ?? 'null')
  const type: LoginType = mindToken && JSON.stringify(mindToken) !== '{}' ? 'token' : 'password'
  const token: string = JSON.stringify(mindToken) || ''
  // 用来获取token登录时的role
  typeAccount = type
  if (mindToken && mindToken.accessToken) {
    const userToken = jwt(JSON.parse(token).accessToken)
    if (userToken.roles) {
      roleAccount = compare(userToken.roles, roles)[0]
    }
  }
  const customKc = new CustomKeycloak({ ...option, type, token })

  try {
    const isLogin = await customKc.login()
    // const isLogin = false

    if (!isLogin) {
      // 重新登录的时候清空一下currentAreaId
      localStorage.removeItem('currentAreaId')
      const currentAccount = localStorage.getItem('currentAccount')
      if (currentAccount) {
        localStorage.removeItem(currentAccount + '-area')
        localStorage.removeItem(currentAccount + '-clearTime')
      }
      localStorage.removeItem('rootConfig')

      return {
        isLogin: false
      }
    }
    store.commit(mutationTypes.SET_KEYCLOAK, customKc)
    setUserInfo(customKc)

    const router = await setTenantAndInitRouter()

    return {
      isLogin: true,
      router
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('token异常', error)
    localStorage.removeItem('keycloak')

    location.reload()
    return {
      isLogin: false
    }
  }
}

export default login
