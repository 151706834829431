import { MutationTree, ActionTree } from 'vuex'
import { State as RootState } from '../state'

type State = {
  list: any[]
  timer: number
  type: string
}

const mutationTypes = {
  SET_BASE_REALTIME_ADD: 'base/realTime/set/ADD',
  SET_BASE_REALTIME_START: 'base/realTime/set/START',
  ACT_BASE_REALTIME_END: 'base/realTime/act/END'
}

const actionTypes = {
  ACT_BASE_REALTIME_ADD: 'base/realTime/act/ADD',
  ACT_BASE_REALTIME_START: 'base/realTime/act/START',
  ACT_BASE_REALTIME_END: 'base/realTime/act/END'
}

const state: State = {
  list: [],
  timer: -1,
  type: 'clear'
}

const mutations: MutationTree<State> = {
  [mutationTypes.SET_BASE_REALTIME_ADD](state, data) {
    state.list.push(data)
  },
  [mutationTypes.SET_BASE_REALTIME_START](state, timer) {
    state.timer = timer
  },
  [mutationTypes.ACT_BASE_REALTIME_END](state, type) {
    state.list = []
    state.type = type
  }
}

const instances: any[] = []
const times: any[] = []
let timer = 0

const actions: ActionTree<State, RootState> = {
  [actionTypes.ACT_BASE_REALTIME_ADD]({ commit }, { fun, time, instance, name }) {
    instances.push(instance)
    times.push(+new Date())
    commit(mutationTypes.SET_BASE_REALTIME_ADD, { time, fun, name })
  },

  [actionTypes.ACT_BASE_REALTIME_START]({ state, commit }, time) {
    timer = window.setInterval(() => {
      const now = +new Date()
      state.list.forEach((e, i) => {
        const { time, fun } = e
        if (times[i] + time <= now) {
          const o = { f: fun }
          o.f.call(instances[i])
          times[i] = now
        }
      })
    }, time)
    commit(mutationTypes.SET_BASE_REALTIME_START, timer)
  },

  [actionTypes.ACT_BASE_REALTIME_END]({ commit }) {
    commit(mutationTypes.ACT_BASE_REALTIME_END)
    window.clearInterval(timer)
  }
}

export default { state, actions, mutations }
export { mutationTypes, actionTypes }
