import _ from 'lodash'
import cssVars from 'css-vars-ponyfill'
import { generate } from '@ant-design/colors'
import { color } from './color'
import common from './themes/common'
import dark from './themes/dark.theme'
import light from './themes/light.theme'
import colors from '@/styles/color.scss'

const themes = { dark, light }
const STORAGE_KEY = 'theme-name'

type ThemeName = keyof typeof themes

const hyphenate = (a: string) => (a + '').replace(/[A-Z]/g, match => '-' + match.toLowerCase())
const isThemeName = (a: unknown): a is ThemeName => Object.keys(themes).some(key => key === a)

let themeName: ThemeName = 'dark'
let variables: Record<string, string> = {}

const setCssVariables = () => {
  const variables: Record<string, string> = { ...common, ...themes[themeName] }

  cssVars({
    watch: true,
    variables: {
      ..._.mapKeys(variables, (_, key) => `--${hyphenate(key)}`),
      ..._.mapKeys(
        _.mapValues(variables, value => {
          if (color.test(value)) {
            const { r, g, b } = color.parse(value)

            return `${r}, ${g}, ${b}`
          }

          return value
        }),
        (_, key) => `--${hyphenate(key + 'Rgb')}`
      )
    }
  })
}

const rgba = (text: string, alpha: number) => {
  if (color.test(text)) {
    const { r, g, b } = color.parse(text)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  return 'rgba(0, 0, 0, 0)'
}

export default {
  init: (options: { name?: ThemeName } = {}) => {
    const name = localStorage.getItem(STORAGE_KEY)

    if (isThemeName(name)) {
      themeName = name
    } else {
      const { name } = options

      if (name) {
        themeName = name
      }
    }

    variables = { ...colors, ...common, ...themes[themeName] }

    setCssVariables()
  },

  setTheme: (themeName: ThemeName) => {
    localStorage.setItem(STORAGE_KEY, themeName)
  }
}

export { themeName, variables, rgba, generate }
