import store from '@/store'
import { Request } from './request'

export class Europa extends Request {
  constructor() {
    super('/europa/console')
  }

  // person
  getPerson(id = '') {
    return this.get(`/persons/${id}`)
  }

  getPersonCount(params = {}) {
    return this.get('/persons/count', { params })
  }

  getPersons(data = {}) {
    return this.post('/persons/search', data)
  }

  putPerson(id = '', data = {}) {
    return this.put(`/persons/${id}`, data)
  }

  exportPerson(data = {}) {
    return this.post('/persons/export', data)
  }

  addPersonImage(id = '', data = {}) {
    return this.post(`/persons/${id}/images`, data)
  }

  deletePerson(id: string, leaseId: string) {
    return this.delete(`/persons/${id}?leaseId=${leaseId}`)
  }

  getNationals() {
    return this.get(`/persons/nationalities`)
  }

  getPoliticals() {
    return this.get(`/persons/politicals`)
  }

  getProfessions() {
    return this.get(`/persons/professions`)
  }

  addPupils(id = '', data = {}) {
    return this.post(`/persons/${id}/pupils`, data)
  }

  getPupils(id = '') {
    return this.get(`/persons/${id}/pupils`)
  }

  putImage(id = '', data = {}) {
    return this.get(`/persons/${id}/images`, data)
  }

  updateRooms(id = '', data = {}) {
    return this.put(`/persons/room/${id}`, data)
  }

  // house
  getHouse(id = '') {
    return this.get(`/houses/${id}`)
  }

  getHouseStatistic(data?: AnyObj) {
    return this.post('/houses/statistics', data)
  }

  getHouseLists(data = {}) {
    return this.post('/houses/search', data)
  }

  createHouse(data = {}) {
    return this.post(`/houses`, data)
  }

  batchCreateHouse(data = {}) {
    return this.post(`/houses/batch`, data)
  }

  updateHouse(id = '', data = {}) {
    return this.put(`/houses/${id}`, data)
  }

  deleteHouse(id = '') {
    return this.delete(`/houses/${id}`)
  }

  getHousesByPerson(id = '') {
    return this.get(`/persons/${id}/houses`)
  }

  getPersonByHouse(id: string) {
    return this.get(`/houses/${id}/persons`)
  }

  getRentalRate(data = '') {
    return this.post(`/houses/rentalRate`, data)
  }

  // room
  getRoom(id = '') {
    return this.get(`/rooms/${id}`)
  }

  getRooms(data = {}) {
    return this.post(`/rooms/search`, data)
  }

  createRooms(data = {}) {
    return this.post(`/rooms`, data)
  }

  updateRoom(id = '', data = {}) {
    return this.put(`/rooms/${id}`, data)
  }

  deleteRoom(id = '') {
    return this.delete(`/rooms/${id}`)
  }

  personByRoom(id = '') {
    return this.get(`/rooms/${id}/persons`)
  }

  audits(data = {}) {
    return this.post(`/audits/search`, data)
  }

  putAudits(id = '', data = {}) {
    return this.put(`/audits/${id}`, data)
  }

  // 租客操作，退租，入住，换租
  inRent(id = '', data = {}) {
    return this.post(`/rooms/${id}/rent`, data)
  }

  changeRent(id = '', roomId = '') {
    return this.put(`/leases/${id}?roomId=${roomId}`)
  }

  exitRent(id = '', countryId = '') {
    return this.post(`/persons/${id}/lease-off?countryId=${countryId}`)
  }

  // 区域数
  getRootAreaTree() {
    return this.get('/areas/tree', { timeout: 10000, headers: { 'no-root': true } })
  }

  getAreaTree() {
    return this.get(`/areas/tree`)
  }

  createArea(data = {}) {
    return this.post(`/areas`, data)
  }

  updateArea(id = '', data = {}) {
    return this.put(`/areas/${id}`, data)
  }

  deleteArea(id = '') {
    return this.delete(`/areas/${id}`)
  }
  getArea(id = '') {
    return this.get(`/areas/${id}`)
  }
  // lease
  getLandlordByLease(id = '') {
    return this.get(`/leases/${id}/landlord`)
  }

  createDevice(data: AnyObj) {
    return this.put(`/devices/${data.deviceId}/bind/areas/${data.areaId}`)
  }
  updateDevice(id: string, data: AnyObj) {
    return this.put('/devices/' + id, data)
  }

  secretConsole() {
    return this.get('/tools/upload-secret')
  }

  imageQuality(imageUrl: string) {
    return this.post('/tools/image-quality', { imageUrl })
  }

  getVisitors(data: AnyObj) {
    return this.post('/visitors/search', data)
  }

  createVisitor(data: AnyObj) {
    return this.post('/visitors', data)
  }

  searchVisitorApplications(data?: AnyObj) {
    return this.post('/visitors/applications/search', data)
  }

  updateVisitorApplication(id: string, data: AnyObj) {
    return this.put(`/visitors/applications/${id}`, data)
  }

  getPersonTrend(params?: { type?: 'day' | 'week' | 'month'; cycle?: number }) {
    return this.get('/statistics/trend', { params })
  }

  getPersonRealtimeNumber() {
    return this.get('/statistics/realtime')
  }

  getPersonMouthNumber() {
    return this.get('/statistics/month')
  }

  getAnomalousEvents(data: { startTime: any; endTime: any }) {
    return this.post('/persons/anomalous/event/statistics', data)
  }

  getRoomStatistic(data?: { areaIds?: string[] }) {
    return this.post('/rooms/statistics', data)
  }

  getLocationStatistic(data?: { areaIds?: string[]; limit?: number; offset?: number }) {
    return this.post('/devices/statistics', data)
  }

  updateLease(id: string, data: AnyObj) {
    return this.put(`/leases/${id}`, data)
  }

  updateLeases(data: { leaseIds: string[]; expireMonths?: number; ignore?: boolean }) {
    return this.put(`/leases`, data)
  }

  getPassagesSearch(opt: AnyObj) {
    return this.post('/passages/detail/search', opt)
  }

  getPassagesExport(opt: AnyObj) {
    return this.post('/passages/detail/export', opt, { timeout: 300000 })
  }

  // Request local service
  createPerson(data = {}) {
    return this.post('/persons', data, { baseURL: store.getters.baseURL })
  }
}

export default new Europa()
