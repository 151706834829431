import codeMapJson from './codeMap.json'
import errorMapJson from './errorMap.json'

type CodeMap = { [key: number]: string }
type ErrorMap = { [key: number]: { [key: string]: string } }
type MsgParam = { code?: number; status?: number; message?: string }

const getMsg = ({ code, status, message }: MsgParam): string => {
  const codeMap: CodeMap = codeMapJson
  const errorMap: ErrorMap = errorMapJson

  if (code && codeMap[code]) return codeMap[code]

  if (status && errorMap[status]) {
    if (message && errorMap[status][message]) return errorMap[status][message]

    // If no Chinese error message is matched, the original error message will be returned
    return message ?? errorMap[status].default
  }

  return message ?? ''
}

export { getMsg }
