



















































import { compareTagOrArea } from '@/utils'
import { Vue, Component, Model, Prop, Provide } from 'vue-property-decorator'
import storage from './storage'
import store, { actionTypes } from '@/store'

@Component
export default class FilterHub extends Vue {
  @Model('change', { type: Object, default: () => ({}) }) value: Record<string, any>

  /**
   * 锁定时作为键值存入 localStorage
   */
  @Prop({ required: true }) name: string
  /**
   * 控制是否显示还原和锁定按钮
   */
  @Prop({ default: () => ({ clear: true, lock: true }) })
  ctrlVisible: { clear: boolean; lock: boolean }
  /**
   * 筛选器默认状态，若不传入则默认状态为挂载完成后的 v-model
   */
  @Prop() defaultValue?: Record<string, any>

  @Provide()
  public filter(prop?: string) {
    this.$nextTick(() => {
      /**
       * 筛选项监听事件被触发后触发
       *
       * @property {string} prop 触发项的 prop 值
       */
      this.$emit('filter', prop)
    })
  }

  @Provide()
  public clear() {
    storage.remove(this.name)
    this.initialize()
    this.filter()

    /**
     * 点击还原按钮后触发
     */
    this.$emit('clear')
  }

  @Provide() public expanded = false
  @Provide() public locked = false

  private initialValue: Record<string, any> = {}

  private get syncedValue() {
    return this.value
  }

  private set syncedValue(value) {
    /**
     * 绑定值被改变时触发
     *
     * @property {Object} prop 组件绑定值
     */
    this.$emit('change', value)
  }

  private get expandable() {
    return !!this.$scopedSlots.content
  }

  private mounted() {
    this.initialValue = { ...(this.defaultValue ?? this.value) }
    this.syncStorage()
  }

  private initialize() {
    this.syncedValue = { ...this.initialValue }
  }

  private toggleExpanded() {
    if (this.expandable) {
      this.expanded = !this.expanded
    }
  }

  private toggleLocked() {
    this.locked = !this.locked

    if (this.locked) {
      storage.set(this.name, this.value)
    } else {
      storage.remove(this.name)
    }
  }

  /**
   * 检查当前锁定的值是否在数据库中已经被删除
   */
  private async checkValue(data: any) {
    if (!data) return false

    let usable = true
    const { areaIds, identity } = data
    if (areaIds) {
      await store.dispatch(actionTypes.FETCH_BASE_AREAS)
      usable = compareTagOrArea('area', areaIds)
    }

    if (usable && identity && identity.tagIds) {
      await store.dispatch(actionTypes.FETCH_TAGS)
      usable = compareTagOrArea('tag', identity.tagIds)
    }
    return usable
  }

  private async syncStorage() {
    const data = storage.get(this.name)

    if (data) {
      this.locked = true
      this.syncedValue = data

      const usable = await this.checkValue(data)
      if (!usable) {
        this.clear()
        this.locked = false
      }
    } else {
      this.initialize()
    }
  }
}
