




import { Vue, Component } from 'vue-property-decorator'
import theme from '@/libs/theme'
import store from '@/store'

@Component
export default class App extends Vue {
  created() {
    const themeName = store.state.features?.theme || 'dark'

    theme.init({ name: themeName })
  }

  mounted() {
    addEventListener('keydown', (e: any) => {
      const preventCodes = ['Space', 'Enter', 'NumpadEnter']

      if (e.path && e.path[0].nodeName === 'BUTTON' && preventCodes.includes(e.code)) {
        e.preventDefault()
      }
    })
  }
}
