import { arrToTree, treeToArr } from '@/utils/helpers'

// 每一种角色中新添加的功能要严格体现出父子关系
// 对于新添加的功能根节点的id为management
// 该功能若为一级菜单，则parentId为management
// 该功能若为二级菜单，则parentId为对应的 一级菜单，以此类推
// 目前共有五种角色
// star-admin: 商业认知-管理员
// passage-admin: 智慧通行-管理员
// realty-admin: 智慧案场-管理员
// country-admin: 社会治理-管理员
// country-user: 社会治理-操作员

const roles: { [key: string]: RoutePath[] } = {
  'star-admin': [
    { id: 'management', parentId: '0' },
    { id: 'home', parentId: 'management' },
    { id: 'pass-management', parentId: 'management' },
    { id: 'passing-detail', parentId: 'pass-management' },
    { id: 'passing-statistic', parentId: 'pass-management' },
    { id: 'personnel-management', parentId: 'management' },
    { id: 'personnel-info', parentId: 'personnel-management' },
    { id: 'tag-management', parentId: 'personnel-management' },
    { id: 'group-detail', parentId: 'personnel-management' },
    { id: 'group-statistics', parentId: 'personnel-management' },
    { id: 'data-map', parentId: 'personnel-management' },
    { id: 'admin-management', parentId: 'management' },
    { id: 'account-management', parentId: 'admin-management' },
    { id: 'area-statistics', parentId: 'admin-management' },
    { id: 'equipment-management', parentId: 'admin-management' },
    { id: 'equipment-repository', parentId: 'admin-management' },
    { id: 'map-management', parentId: 'admin-management' },
    { id: 'personnel-insight', parentId: 'management' },
    { id: 'face-recognition', parentId: 'personnel-insight' },
    { id: 'face-comparison', parentId: 'personnel-insight' },
    { id: 'personnel-search', parentId: 'personnel-insight' },
    { id: 'cube', parentId: 'management' }
  ],
  'passage-admin': [
    { id: 'management', parentId: '0' },
    { id: 'pass-management', parentId: 'management' },
    { id: 'passing-detail', parentId: 'pass-management' },
    { id: 'passing-statistic', parentId: 'pass-management' },
    { id: 'personnel-management', parentId: 'management' },
    { id: 'personnel-info', parentId: 'personnel-management' },
    { id: 'tag-management', parentId: 'personnel-management' },
    { id: 'admin-management', parentId: 'management' },
    { id: 'account-management', parentId: 'admin-management' },
    { id: 'equipment-management', parentId: 'admin-management' },
    { id: 'equipment-repository', parentId: 'admin-management' },
    { id: 'map-management', parentId: 'admin-management' },
    { id: 'cube', parentId: 'management' }
  ],
  'realty-admin': [
    { id: 'management', parentId: '0' },
    { id: 'home', parentId: 'management' },
    { id: 'personnel-management', parentId: 'management' },
    { id: 'personnel-info', parentId: 'personnel-management' },
    { id: 'tag-management', parentId: 'personnel-management' },
    { id: 'group-detail', parentId: 'personnel-management' },
    { id: 'group-statistics', parentId: 'personnel-management' },
    { id: 'admin-management', parentId: 'management' },
    { id: 'account-management', parentId: 'admin-management' },
    { id: 'area-statistics', parentId: 'admin-management' },
    { id: 'equipment-management', parentId: 'admin-management' },
    { id: 'equipment-repository', parentId: 'admin-management' },
    { id: 'smart-realty', parentId: 'management' },
    { id: 'client-appointment', parentId: 'smart-realty' },
    { id: 'risk-determination', parentId: 'smart-realty' },
    { id: 'visitor-search', parentId: 'smart-realty' },
    { id: 'personnel-insight', parentId: 'management' },
    { id: 'personnel-search', parentId: 'personnel-insight' }
  ],
  'country-admin': [
    { id: 'management', parentId: '0' },
    { id: 'home-data-visualization', parentId: 'management' },
    { id: 'admin-management', parentId: 'management' },
    { id: 'account-management', parentId: 'admin-management' },
    { id: 'device-management', parentId: 'admin-management' },
    { id: 'device-detail', parentId: 'device-management' },
    { id: 'population-management', parentId: 'management' },
    { id: 'resident-management', parentId: 'population-management' },
    { id: 'resident-detail', parentId: 'resident-management' },
    { id: 'information-acquisition', parentId: 'population-management' },
    { id: 'tag-management-country', parentId: 'population-management' },
    { id: 'expired-renters', parentId: 'population-management' },
    { id: 'house-management-level-1', parentId: 'management' },
    { id: 'house-management', parentId: 'house-management-level-1' },
    { id: 'house-detail', parentId: 'house-management' },
    { id: 'pass-management', parentId: 'management' },
    { id: 'passing-detail-country', parentId: 'pass-management' },
    { id: 'passing-statistic-country', parentId: 'pass-management' },
    { id: 'visitor-audit', parentId: 'pass-management' },
    { id: 'visitor-management', parentId: 'pass-management' },
    { id: 'inaction-tenant', parentId: 'event-warning' },
    { id: 'event-warning', parentId: 'management' },
    { id: 'group-leasing', parentId: 'event-warning' },
    { id: 'abnormal-temperature', parentId: 'event-warning' },
    { id: 'caring-elder', parentId: 'event-warning' }
  ],
  'country-user': [
    { id: 'management', parentId: '0' },
    { id: 'population-management', parentId: 'management' },
    { id: 'resident-management', parentId: 'population-management' },
    { id: 'resident-detail', parentId: 'resident-management' },
    { id: 'information-acquisition', parentId: 'population-management' },
    { id: 'expired-renters', parentId: 'population-management' },
    { id: 'house-management-level-1', parentId: 'management' },
    { id: 'house-management', parentId: 'house-management-level-1' },
    { id: 'house-detail', parentId: 'house-management' }
  ],
  'country-readonly': [
    { id: 'management', parentId: '0' },
    { id: 'home-data-visualization', parentId: 'management' },
    { id: 'population-management', parentId: 'management' },
    { id: 'resident-management', parentId: 'population-management' },
    { id: 'resident-detail', parentId: 'resident-management' },
    { id: 'house-management-level-1', parentId: 'management' },
    { id: 'house-management', parentId: 'house-management-level-1' },
    { id: 'house-detail', parentId: 'house-management' },
    { id: 'event-warning', parentId: 'management' },
    { id: 'inaction-tenant', parentId: 'event-warning' },
    { id: 'group-leasing', parentId: 'event-warning' },
    { id: 'abnormal-temperature', parentId: 'event-warning' },
    { id: 'caring-elder', parentId: 'event-warning' },
    { id: 'admin-management', parentId: 'management' },
    { id: 'device-management', parentId: 'admin-management' },
    { id: 'device-detail', parentId: 'device-management' }
  ]
}

const routesMixin = (r1: RoutePath[], r2: RoutePath[]): RoutePath[] => {
  if (r2.length == 0) {
    return r1
  }

  return r1.filter(x => r2.some(y => y.id === x.id))
}

export const normalizationRoutes = (role = 'star-admin', routes: RoutePath | null) => {
  const r = routes && routes.id ? treeToArr(routes) : []
  const routesArr = routesMixin(roles[role], r)

  return arrToTree(routesArr)
}
