import EXIF from 'exif-js'

type Record<K extends string | number | symbol = string, V = any> = { [key in K]: V }

export const record = <T extends Record>(obj: T) => ({
  filter: (predicate: (value: T[keyof T], key: keyof T) => boolean) => {
    const out: Record = {}
    for (const key in obj) {
      if (predicate(obj[key], key)) out[key] = obj[key]
    }
    return out as T
  },

  map: (predicate: (value: T[keyof T], key: keyof T) => any) => {
    const out: Record = {}
    for (const key in obj) {
      out[key] = predicate(obj[key], key)
    }
    return out as T
  },

  mapWithProps: (props: string[], predicate: (value: any, key: string) => any) => {
    const out: Record = {}
    props.forEach(prop => {
      out[prop] = predicate(obj[prop], prop)
    })
    return out
  },

  mapArray: (predicate: (value: T[keyof T], key: keyof T) => any) => {
    const out: T[keyof T][] = []
    for (const key in obj) {
      out.push(predicate(obj[key], key))
    }
    return out
  }
})

export const is = {
  fun: (a: unknown): a is Function => typeof a === 'function',
  str: (a: unknown): a is string => typeof a === 'string',
  num: (a: unknown): a is number => typeof a === 'number',
  und: (a: unknown): a is undefined => a === undefined,
  nul: (a: unknown): a is null => a === null,
  arr: <T = any>(a: unknown): a is Array<T> => Array.isArray(a),
  obj: <K extends string | number | symbol = string, V = any>(a: unknown): a is Record<K, V> =>
    Object.prototype.toString.call(a) === '[object Object]',
  set: <T = any>(a: unknown): a is Set<T> => Object.prototype.toString.call(a) === '[object Set]',
  map: <K = any, V = any>(a: unknown): a is Map<K, V> =>
    Object.prototype.toString.call(a) === '[object Map]',

  emp: (a: any) => {
    if (is.str(a) && a === '') return true
    if (is.arr(a) && a.length === 0) return true
    if (is.obj(a) && Object.entries(a).length === 0) return true

    return false
  },

  ava: (a: any) => {
    return !is.emp(a) && !is.und(a) && !is.nul(a)
  }
}

type TreeNode = { id: string; children?: TreeNode[] }

export const getTreeNodePath = <T extends TreeNode>(root: T, id: string) => {
  const path: T[] = []

  const dfs = (node: T): T | null => {
    path.push(node)

    if (node.id === id) {
      return node
    }

    if (node.children) {
      for (const child of node.children) {
        const node = dfs(child as T)

        if (node) {
          return node
        }
      }
    }

    path.pop()
    return null
  }

  dfs(root)

  return path
}

export const fileToBase64 = (file: File | Blob) =>
  new Promise<string>((resolve, reject) => {
    const fr = new FileReader()

    fr.readAsDataURL(file)

    fr.onload = () => {
      if (is.str(fr.result)) {
        resolve(fr.result)
      }
    }

    fr.onerror = () => {
      reject('read failed!')
    }
  })

type RotateOptions = {
  size?: number
  angle?: 0 | 90 | 180 | 270
}

export const rotateImage = (file: Blob | File, options: RotateOptions = {}): Promise<Blob | null> =>
  new Promise(resolve => {
    const defaultOptions = { size: 1000 }
    const { size, angle } = { ...defaultOptions, ...options }

    const img = new Image()

    img.src = URL.createObjectURL(file)
    img.onload = () => {
      const width = size
      const height = (img.height / img.width) * size

      const rotate = (angle: 0 | 90 | 180 | 270 = 0) => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (angle === 90 || angle === 270) {
          canvas.width = height
          canvas.height = width
        } else {
          canvas.width = width
          canvas.height = height
        }

        if (ctx) {
          if (angle === 90) ctx.transform(0, 1, -1, 0, height, 0)
          if (angle === 180) ctx.transform(-1, 0, 0, -1, width, height)
          if (angle === 270) ctx.transform(0, -1, 1, 0, 0, width)

          ctx.drawImage(img, 0, 0, width, height)
          canvas.toBlob(blob => resolve(blob))
        }
      }

      if (is.num(angle)) {
        rotate(angle)
      } else {
        EXIF.getData(img as any, () => {
          const orientation = EXIF.getTag(img, 'Orientation')
          const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

          if (isIOS) {
            rotate()
          } else {
            if (orientation === 3) rotate(180)
            else if (orientation === 6) rotate(90)
            else if (orientation === 8) rotate(270)
            else rotate()
          }
        })
      }
    }
  })

export const validate: Record<string, (v: string) => boolean> = {
  identityNumber: (identityNumber: string) => {
    const weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

    const code = identityNumber + ''
    const last = identityNumber[17]

    const seventeen = code.substring(0, 17)

    const arr = seventeen.split('')
    const len = arr.length
    let num = 0
    for (let i = 0; i < len; i++) {
      num = num + parseInt(arr[i]) * weightFactor[i]
    }

    const resisue = num % 11
    const lastNo = checkCode[resisue]

    const idcardPatter =
      /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

    const format = idcardPatter.test(identityNumber)

    return last === lastNo && format ? true : false
  },

  passportNumber: (v: string) => /^[a-zA-Z0-9]{5,17}$/.test(v),

  hkmoPermitNumber: (v: string) => /^[HM][0-9]{10}$/.test(v),

  twnPermitNumber: (v: string) => /^([0-9]{8}|[0-9]{10})$/.test(v),

  phoneNumber: (tel: string) => /^1[3456789]\d{9}$/.test(tel),

  landlineNumber: (val: string) => /0\d{2,3}-\d{7,8}(-\d{1,6})?/.test(val),

  simplifiedLandlineNumber: (val: string) => /^\d{7,8}(-\d{1,6})?$/.test(val)
}

export const phoneNumber = {
  simplify: (number: string) => number.replace('+86', ''),
  standardize: (number: string) => '+86' + phoneNumber.simplify(number)
}

export const landline = {
  separate: (tel: string) => {
    const index = tel.indexOf('-')
    const areaCode = tel.substring(0, index)
    const number = tel.substring(index + 1, tel.length)
    return { areaCode, number }
  },
  splicing: (areaCode: string, number: string) => {
    return areaCode + `${areaCode && number ? '-' : ''}` + number
  }
}

export const telType = (tel: string) => (tel && tel.indexOf('-') > 0 ? 'landline' : 'phone')

export const telNumber = {
  analysis: (tel: string) => {
    const type = telType(tel)
    if (type === 'landline') {
      const { areaCode, number } = landline.separate(tel)
      return { type, areaCode, number }
    } else {
      let number = ''
      if (tel) number = phoneNumber.simplify(tel)
      return { type, areaCode: '', number }
    }
  }
}

export const round = (num: number, n: number) => Math.round(num * Math.pow(10, n)) / Math.pow(10, n)

export const identityNumber = {
  getGender: (val: string) => (parseInt(val.substr(16, 1)) % 2 == 1 ? 'male' : 'female')
}

export const identityMap: any = { white: '白名单', vip: 'VIP', black: '黑名单', strange: '陌生人' }

export const arrToTree = (data: any[], key = 'id', parentKey = 'parentId') => {
  if (!data || !data.length) return []
  const treeMapList = data.reduce((memo, current) => {
    memo[current[key]] = current
    return memo
  }, {})

  const result = data.reduce((arr, current) => {
    const pid = current[parentKey]
    const parent = treeMapList[pid]
    if (parent) {
      parent.children ? parent.children.push(current) : (parent.children = [current])
    } else if (pid == '0') {
      arr.push(current)
    }
    return arr
  }, [])

  return result
}

export const treeToArr = (data: any, key = 'id', parentKey = 'parentId'): RoutePath[] => {
  if (!data) return []
  if (!is.arr(data)) data = [data]

  const result: any[] = []
  function parse(data: any) {
    if (data instanceof Array) {
      data.forEach(ele => {
        parse(ele)
      })
    } else {
      result.push({
        [key]: data[key],
        [parentKey]: data[parentKey]
      })
      if (data.children && data.children.length) {
        parse(data.children)
      }
    }
  }
  parse(data)
  return result
}

export const blobToJson = async (data: Blob) => {
  const loadFile = () =>
    new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsText(data)
      reader.onload = e => resolve(e.target && e.target.result)
    })
  const result: any = await loadFile()
  return JSON.parse(result)
}

export const imageData = {
  simplify: (data: string) => data.replace(/^data:image\/\w+;base64,/, ''),
  standardize: (data: string) => 'data:image/jpeg;base64,' + imageData.simplify(data)
}

export const removeObjectEmptyProperty = (obj: any) => {
  const finalObj: any = {}
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const nestedObj = removeObjectEmptyProperty(obj[key])
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj
      }
    } else if (Array.isArray(obj[key])) {
      if (obj[key].length) {
        obj[key].forEach((x: any) => {
          const nestedObj = removeObjectEmptyProperty(x)
          if (Object.keys(nestedObj).length) {
            finalObj[key] = finalObj[key] ? [...finalObj[key], nestedObj] : [nestedObj]
          }
        })
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key]
    }
  })
  return finalObj
}
