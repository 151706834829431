import axios from 'axios'
import Message from '@/components/common/Message'
import { getMsg } from './msgMap'
import store from '@/store'
import { blobToJson } from '@/utils/helpers'

// axios.defaults.retry = 0
axios.defaults.timeout = 60000
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.Pragma = 'no-cache'
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store'
axios.defaults.baseURL = '/api/v1'

const showErrMsg = (message: string) =>
  Message({ type: 'error', message, duration: 10 * 1000, showClose: true })

let noNetwork = false

axios.interceptors.request.use(config => {
  const kc = store.state.keycloak
  const rootConfig = store.state.config?.root

  if (rootConfig && !config.headers['no-root']) {
    config.headers['root'] = rootConfig.id
  }
  if (kc) {
    kc.setToken(config.headers)
  }

  return config
})

axios.interceptors.response.use(
  response => {
    noNetwork = false
    let isShowMessage = true
    const hideMessage = () => (isShowMessage = false)

    const { code, error } = response.data

    if (code && error) {
      let errMsg = ''
      const { detail, message, description } = error
      if (description) {
        errMsg = description
      } else if (code === 4002) {
        if (detail && detail.length > 0) {
          errMsg = detail[0]
        } else {
          errMsg = message
        }
      } else if (getMsg({ code })) {
        errMsg = getMsg({ code })
      } else {
        if (detail && detail.length > 0) {
          errMsg = `${message}, ${detail[0]}`
        } else {
          errMsg = message
        }
      }

      response.data = { code, error: { message: errMsg } }

      setTimeout(() => {
        if (isShowMessage) showErrMsg(errMsg)
      })
    }

    return { ...response, hideMessage }
  },
  async err => {
    let isShowMessage = true
    const hideMessage = () => (isShowMessage = false)

    const response = { ...err.response }
    const { data = {}, status = 0 } = response

    let errMsg = ''

    if (!err.message) err.cancel = 'true'

    if (status === 401) {
      const redirectUri = location.origin || '/'
      const kc = store.state.keycloak
      if (kc && kc.logout) {
        if (!kc.kcOptions.reconnecting) kc.logout({ redirectUri })
      } else {
        location.reload()
      }
    } else if (!window.navigator.onLine) {
      noNetwork = true

      errMsg = '网络已断开，请检查网络连接!'
    } else if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
      errMsg = '网络状况不佳，请检查网络连接!'
    } else if (status === 404 && !data.error && data.type === 'application/json') {
      return { data: await blobToJson(data) }
    } else if (getMsg({ status })) {
      errMsg = getMsg({ status })
    } else {
      errMsg = '未知错误'
    }

    if (data.error) {
      const { message, detail, description } = data.error
      if (description) {
        errMsg = description
      } else if (detail && detail.length > 0 && getMsg({ status, message: detail[0] })) {
        errMsg = getMsg({ status, message: detail[0] })
      } else if (getMsg({ status, message })) {
        errMsg = getMsg({ status, message })
      } else {
        errMsg = message
      }
    }
    const jsonData = data.type === 'application/json' ? await blobToJson(data) : data
    response.data = {
      error: { message: errMsg, description: jsonData?.error?.description ?? undefined }
    }

    setTimeout(() => {
      if (isShowMessage && localStorage.getItem('isShowMessage') != 'false') {
        showErrMsg(errMsg)
      }
      if (localStorage.getItem('isShowMessage') == 'false') {
        localStorage.setItem('isShowMessage', 'true')
      }
    })

    return { ...response, hideMessage }
  }
)

export default axios
export { axios, noNetwork }
