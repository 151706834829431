import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import state from './state'
import getters from './getters'
import mutations, { mutationTypes } from './mutations'
import actions, { actionTypes } from './actions'
import realTime from './realTime'
import areas from './modules/areas'
import iot from './iot'

Vue.use(Vuex)

const store = new Store({
  // strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions,
  modules: { realTime, areas, iot }
})

export default store
export { mutationTypes, actionTypes }
