import api from '@/api'
import store from '@/store'

export async function getAllTags() {
  const tenantId = store.state.tenantId
  if (tenantId === 'e8177f4b-5fdc-42ba-8697-491e3c477900') {
    return []
  }
  const tagsList = []
  const resp: AnyObj = await api.base.getAllTags()
  if (resp.data && resp.data.data) {
    tagsList.push(...resp.data.data)
  }
  return tagsList
}
