import moment from 'moment'
import Message from '@/components/common/Message'
import store from '@/store'
import treeFind from '@/libs/operation-tree-node/treeFind'
import { AxiosResponse } from 'axios'

const conf = {
  ageGroup: [
    [1, 17],
    [18, 25],
    [26, 35],
    [36, 45],
    [46, 55],
    [56, 65],
    [66, 100]
  ],
  attrConfig: {
    gender: [
      { Id: 0, Name: '未知' },
      { Id: 1, Name: '男' },
      { Id: 2, Name: '女' }
    ],
    nation: [
      { Id: 0, Name: '未知' },
      { Id: 1, Name: '其他民族' },
      { Id: 2, Name: '维吾尔族' }
    ],
    glass: [
      { Id: 0, Name: '戴眼镜未知' },
      { Id: 1, Name: '无眼镜' },
      { Id: 2, Name: '戴眼镜' },
      { Id: 3, Name: '戴太阳镜' }
    ],
    hat: [
      { Id: 0, Name: '戴帽子未知' },
      { Id: 1, Name: '无帽子' },
      { Id: 2, Name: '戴帽子' },
      { Id: 3, Name: '戴头巾' }
    ],
    credentialType: [
      { Id: 1, Name: '身份证' },
      { Id: 2, Name: '护照' },
      { Id: 3, Name: '警官证' },
      { Id: 4, Name: '军官证' },
      { Id: 5, Name: '临时身份证' }
    ]
  }
}

export const vehicleTypes = [
  { label: '轿车', value: 'car' },
  { label: '面包车', value: 'van' },
  { label: '皮卡', value: 'pickup' },
  { label: '越野车/SUV', value: 'SUV' },
  { label: '商务车/MPV', value: 'MPV' },
  { label: '轻型客车', value: 'light bus' },
  { label: '中型客车', value: 'medium bus' },
  { label: '大型客车', value: 'large bus' },
  { label: '公交车', value: 'bus' },
  { label: '校车', value: 'school bus' },
  { label: '微型货车', value: 'mini truck' },
  { label: '轻型货车', value: 'light truck' },
  { label: '中型货车', value: 'medium truck' },
  { label: '大型货车', value: 'large truck' },
  { label: '重型货车', value: 'heavy truck' },
  { label: '集装箱车', value: 'container' },
  { label: '叉车', value: 'forklift' },
  { label: '其它', value: 'other' }
]

export const nonVehicleTypes = [
  { label: '三轮车(有篷)', value: 'tricycle with roof' },
  { label: '三轮车(封闭驾驶舱)', value: 'tricycle with seal' },
  { label: '三轮车(无篷&无封闭)', value: 'tricycle with no roof' },
  { label: '自行车', value: 'bicycle' },
  { label: '摩托车/电瓶车', value: 'motorcycle' }
]

export const vehicleColors = [
  '白色',
  '灰色',
  '黑色',
  '绿色',
  '蓝色',
  '红色',
  '紫色',
  '黄色',
  '粉色',
  '橙色',
  '棕色',
  '银色'
]

export const nonVehicleColors = [
  '白色',
  '灰色',
  '黑色',
  '绿色',
  '蓝色',
  '红色',
  '紫色',
  '黄色',
  '粉色',
  '橙色',
  '棕色',
  '花色'
]

export const now = () => new Date().getTime()

export function formTime(ts: any, f: string) {
  if ((ts + '').length < 13) {
    return false
  }
  ts = parseInt(ts.toString().slice(0, 13))

  if (f) {
    let time
    if (f === 'hour') {
      time = moment(ts).format('HH:mm:ss')
    }
    if (f === 'year') {
      time = moment(ts).format('YYYY/MM/DD')
    }
    return time
  }
  return moment(ts).format('YYYY/MM/DD HH:mm:ss')
}

export function ageGroup(age: number) {
  const ageGroup = conf.ageGroup
  // let age = this.person.Age;
  const a = ageGroup.find(arr => {
    return age <= arr[1] && age >= arr[0]
  })
  if (!a) {
    return '未知'
  }
  if (a) {
    if (a === ageGroup[0]) {
      return `小于 ${ageGroup[1][0]}`
    }
    if (a === ageGroup[ageGroup.length - 1]) {
      return `大于 ${ageGroup[ageGroup.length - 2][1]}`
    }
  }
  return `${a[0]} - ${a[1]}`
}

export function parseGender(gender: string) {
  return gender === 'male' ? '男' : gender === 'female' ? '女' : '-'
}

export function parseHat(hat: string) {
  let h = ''
  switch (hat) {
    case 'noHat':
      h = '未戴帽子'
      break
    case 'hat':
      h = '戴普通帽子'
      break
    case 'scraft':
      h = '戴头巾'
      break
    default:
      h = '未知'
      break
  }
  return h
}

export function parseGlass(glass: string) {
  let g = ''
  switch (glass) {
    case 'noGlass':
      g = '未戴眼镜'
      break
    case 'glass':
      g = '戴普通眼镜'
      break
    case 'sunglass':
      g = '戴太阳镜'
      break
    default:
      g = '未知'
      break
  }
  return g
}

export function repoType(type: string) {
  if (type === 'white') {
    return '白名单'
  }
  if (type === 'black') {
    return '黑名单'
  }
  if (type === 'vip') {
    return 'VIP'
  }
  return type
}

export function getAttr(obj: any, props: string, def?: boolean) {
  if (obj === null || typeof props !== 'string') return def
  const temp: string[] = props.split('.')
  const arr: any[] = []
  const fieldArr = arr.concat(temp)
  temp.forEach(e => {
    if (/^(\w+)\[(\w+)\]$/.test(e)) {
      const matchs = e.match(/^(\w+)\[(\w+)\]$/)
      if (matchs) {
        const field1 = matchs[1]
        const field2 = matchs[2]
        const index = fieldArr.indexOf(e)
        fieldArr.splice(index, 1, field1, field2)
      }
    }
  })
  return fieldArr.reduce((pre, cur) => {
    const target = (pre && pre[cur]) || def

    if (target instanceof Array) {
      const list: any[] = []
      return list.concat(target)
    }
    if (target instanceof Object) {
      return Object.assign({}, target)
    }
    return target
  }, obj)
}
export enum timeType {
  A_SECTIONS = 1,
  TWO_SECTIONS,
  THREE_SECTIONS,
  FOUR_SECTIONS
}
export function getDuration(seconds: number, type: timeType, isOptimize?: boolean) {
  // seconds：时间(毫秒)、type：展示几段式、isOptimize：如果时间为0时是否优化为1秒
  let res
  if (seconds <= 0) {
    res = isOptimize ? '1秒' : '0秒'
  } else {
    const duration = moment.duration(seconds, 'seconds')
    const days = duration.get('days')
    const hour = duration.get('hours')
    const minute = duration.get('minutes')
    const second = duration.get('seconds')
    switch (type) {
      case 1:
        res = `${days ? days + '天' : ''}${!days && hour ? hour + '小时' : ''}${
          !days && !hour && minute ? minute + '分钟' : ''
        }${!days && !hour && !minute && second ? second + '秒' : ''}`
        break
      case 2:
        res = `${days ? days + '天' : ''}${hour ? hour + '小时' : ''}${
          !days && minute ? minute + '分钟' : ''
        }${!days && !hour && second ? second + '秒' : ''}`
        break
      case 3:
        res = `${days ? days + '天' : ''}${
          hour ? hour + '小时' : days && minute ? hour + '小时' : ''
        }${minute ? minute + '分钟' : !days && hour && second ? minute + '分钟' : ''}${
          !days && second ? second + '秒' : ''
        }`
        break
      case 4:
        res = `${days ? days + '天' : ''}${
          days && (minute || second) ? hour + '小时' : hour ? hour + '小时' : ''
        }${second && (days || hour) ? minute + '分钟' : minute ? minute + '分钟' : ''}${
          second ? second + '秒' : ''
        }`
        break
      default:
        break
    }
  }
  return res
}
export function formatDateStart(time: any) {
  // 当天00:00
  const date = new Date(time)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return new Date(date.getTime())
}
export function formatDateEnd(time: any) {
  const date = new Date(time)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return new Date(date.getTime() + 24 * 60 * 60 * 1000 - 1)
}
export function judgeValue(obj: any) {
  const { entries } = Object
  for (const [key, value] of entries(obj)) {
    if (value === '') {
      obj[key] = null
    } else if (value && typeof value == 'string' && value.constructor == String) {
      const res = value.replace(/^\s+|\s+$/g, '')
      if (res.length === 0) {
        obj[key] = null
      }
    }
  }
}

export const compareTagOrArea = (type: 'tag' | 'area', value: any) => {
  let usable = true
  if (type == 'tag') {
    const tags: any[] = store.state.tags
    for (const v of value) {
      if (tags.find(item => item.id == v) == undefined) {
        usable = false
        break
      }
    }
  }

  if (type == 'area') {
    const areas: any[] = store.state.baseAreas
    for (const v of value) {
      const area = treeFind(areas, area => area.id === v)
      if (!area) {
        usable = false
        break
      }
    }
  }

  if (usable == false) {
    const message = '筛选条件中的部分数据被删除，已为您恢复默认状态并重新查询。'
    Message({ type: 'error', message, duration: 4000, showClose: true })
    return usable
  }

  return usable
}

export const fetchAll = async <T extends any[]>(
  fn: (
    offset: number
  ) => Promise<AxiosResponse<{ code: number; count: number; data: T; error?: any }>>
) => {
  const list: T[] = []

  const fetch = () =>
    fn(list.length).then(({ data: { code, data, count } }) => {
      return code === 0 ? { data, count } : { data: [], count: 0 }
    })

  const { data, count } = await fetch()

  list.push(...data)

  while (list.length < count) {
    const { data } = await fetch()

    list.push(...data)
  }

  return list
}
