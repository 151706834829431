import * as fundebug from 'fundebug-javascript'
import fundebugVue from 'fundebug-vue'
import Vue from 'vue'
import { IConfigs } from 'fundebug-javascript'
export const monitor = (apikey: any, username = '') => {
  ;(fundebug as any).apikey = apikey

  fundebug.init({
    filters: [
      {
        req: {
          url: /devstar|localhost|192.168./g
        }
      },
      {
        res: {
          status: /^401$/
        }
      },
      {
        res: {
          status: /^403$/
        }
      },
      {
        res: {
          status: /^0$/
        }
      }
    ],
    silentResource: true,
    silentWebsocket: true,
    user: {
      name: username,
      email: ''
    }
  } as IConfigs)

  return fundebugVue(fundebug, Vue)
}
