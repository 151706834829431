class Options {
  txt = ''
  x = 0
  y = 140
  minWidth = 180
  minHeight = 180
  color = '#fff'
  strokeColor = '#000'
  fontSize = 20
  alpha = 0.15
  angle = -45
}

class Watermark {
  private element: HTMLElement | null = null
  private wrapper: HTMLElement | null = null
  private options: Options = new Options()
  private observers: MutationObserver[] = []

  constructor(options?: { [K in keyof Options]?: Options[K] }) {
    this.setOptions(options)
  }

  setOptions(options?: { [K in keyof Options]?: Options[K] }) {
    this.options = { ...this.options, ...options }
  }

  render(element?: HTMLElement | string) {
    this.clear()

    if (element instanceof HTMLElement) {
      this.element = element
    } else if (typeof element === 'string') {
      this.element = document.querySelector(element)
    } else {
      this.element = document.createElement('div')
      document.body.appendChild(this.element)
    }

    if (this.element) {
      this.wrapper = this.element.parentElement
      if (!this.element.id) this.element.id = `watermark-${Math.random()}`
    }

    this.setStyle(this.wrapper, { position: 'relative' })
    this.setStyle(this.element, {
      background: `url("${this.getSvg()}")`,
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: '9999',
      pointerEvents: 'none'
    })

    this.observe(() => this.render())
  }

  clear() {
    if (this.wrapper && this.element) this.wrapper.removeChild(this.element)

    this.element = null
    this.wrapper = null

    while (this.observers.length > 0) {
      this.observers.pop()?.disconnect()
    }
  }

  private observe(callback: () => void) {
    const bodyObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.removedNodes.forEach(node => {
          if (this.element && (node as HTMLElement).id === this.element.id) {
            this.element = null
            callback()
          }
        })
      })
    })

    bodyObserver.observe(document.body, { childList: true })
    this.observers.push(bodyObserver)

    if (this.element) {
      const observer = new MutationObserver(() => callback())

      observer.observe(this.element, {
        characterData: true,
        attributes: true,
        childList: true,
        subtree: true
      })

      this.observers.push(observer)
    }
  }

  private setStyle(
    element: HTMLElement | null,
    styles: { [K in keyof CSSStyleDeclaration]?: CSSStyleDeclaration[K] }
  ) {
    if (element) {
      for (const key in styles) {
        const style = styles[key]
        if (style) element.style[key] = style
      }
    }
  }

  private getSvg() {
    const { txt, color, strokeColor, fontSize, alpha, angle } = this.options

    const getLength = (arr: any[]) => {
      return arr.map(item => {
        const length = `${item}`.length
        let chineseLen = 0
        const chinese = `${item}`.match(/[\u0391-\uFFE5]/g)
        if (chinese) chineseLen = chinese.length
        return length - chineseLen + chineseLen * 1.2
      })
    }
    const arr = txt.split('//')
    const count = Math.max(...getLength(arr))
    const width = 19 * count
    const height = 19 * count
    const x = 10
    const y = height - arr.length * 15
    let str = ''
    arr.forEach((item, index) => {
      str = str + `<tspan x="${x}" y="${y + index * 30}">${item}</tspan>`
    })
    const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">
                <text
                    text-anchor="start"
                    stroke="${strokeColor}"
                    stroke-width="1"
                    stroke-opacity="${alpha}"
                    fill="${color}"
                    fill-opacity="${alpha}"
                    transform="rotate(${angle}, ${x} ${y})"
                    font-family="Hiragino Sans GB,宋体,Arial, Helvetica, sans-serif,"
                    font-weight="500"
                    font-size="${fontSize}"
                    >
                    ${str}
                </text>
            </svg>`

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgStr)))}`
  }
}

export default new Watermark()
