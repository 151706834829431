import { MessageBox } from 'element-ui'
import {
  ElMessageBoxOptions,
  MessageBoxData,
  MessageBoxCloseAction,
  ElMessageBoxComponent
} from 'element-ui/types/message-box'

interface ConfirmOption extends ElMessageBoxOptions {
  loading?: boolean
  onConfirm?: () => Promise<void>
  onCancel?: () => Promise<void>
}

interface ConfirmFunction {
  (
    message: string,
    title?: string | ConfirmOption,
    options?: ConfirmOption
  ): Promise<MessageBoxData | void>
}
/**
 * 基于 element-ui MessageBox.confirm 的二次封装，功能为点击MessageBox.confirm确定按钮后按钮loading效果
 * @param {Object} options 参照 element-ui MessageBox 组件 options
 * @param {Boolean} options.loading 可选 是否开启messageBox的确认按钮loading，默认为false
 * @param {Function} options.onConfirm 点击确认按钮后的回调函数，只有当loading为true时生效
 * @param {Function} options.onCancel 点击取消按钮后的回调函数，只有当loading为true时生效
 * @returns MessageBoxData
 */
const confirm: ConfirmFunction = (
  message: string,
  title?: string | ConfirmOption,
  options?: ConfirmOption
) => {
  // 当传递两个参数时，title接收的是option
  if (typeof title === 'object') {
    options = title
    title = ''
  }
  if (options?.loading) {
    return MessageBox.confirm(message, title ?? '', {
      ...options,
      // 不能通过点击遮罩关闭
      closeOnClickModal: false,
      beforeClose: async (
        action: MessageBoxCloseAction,
        instance: ElMessageBoxComponent,
        done: () => void
      ) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          // 关闭取消按钮的显示
          instance.showCancelButton = false
          // 关闭右上角x的显示
          instance.showClose = false
          if (options?.onConfirm) {
            await options.onConfirm().catch(() => {
              done()
              instance.confirmButtonLoading = false
            })
            done()
            instance.confirmButtonLoading = false
          }
        } else {
          if (options?.onCancel) {
            await options.onCancel().catch(() => {
              done()
            })
          }
          done()
        }
      }
    })
  } else {
    // 当loading为false或者没有定义时却传了onConfirm或onCancel时报错
    if (options?.onConfirm || options?.onCancel) {
      throw new Error('cannot use onConfirm or onCancel when loading is false or undefined')
    }
    return MessageBox.confirm(message, title ?? '', options)
  }
}
export default confirm
