import { ActionTree, MutationTree } from 'vuex'
import { State as RootState } from '../state'
import api from '@/api'

type Area = Record<string, any>

type State = {
  areas: Area[]
}

const state: State = {
  areas: []
}

const mutations: MutationTree<State> = {
  setAreas(state, areas: Area[]) {
    state.areas = areas
  }
}

const actions: ActionTree<State, RootState> = {
  async fetchAreas({ commit }) {
    const { data } = await api.europa.getAreaTree()
    if (data.code === 0) {
      commit('setAreas', [data.data])
    }
  }
}

export default { namespaced: true, state, mutations, actions }
