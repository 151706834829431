import { is } from '@/utils/helpers'
import _ from 'lodash'

// e.g. filter-data-secondary/deepglint
const getStorageKey = () => {
  const storageKey = 'filter-data'
  const storageKeySecondary = 'filter-data-secondary'

  const name = localStorage.getItem('currentAccount')
  const rootConfig = JSON.parse(localStorage.getItem('rootConfig') ?? '{}')

  if (!is.emp(rootConfig)) {
    return storageKeySecondary + '/' + name
  }

  return storageKey + '/' + name
}

const getStorageData = () => JSON.parse(localStorage.getItem(getStorageKey()) ?? '{}')

const storage = {
  get: (prop: string) => getStorageData()[prop],

  set: (prop: string, value: any) =>
    localStorage.setItem(getStorageKey(), JSON.stringify({ ...getStorageData(), [prop]: value })),

  remove: (prop: string) =>
    localStorage.setItem(getStorageKey(), JSON.stringify(_.omit(getStorageData(), [prop]))),

  clear: () => localStorage.removeItem(getStorageKey())
}

export default storage
