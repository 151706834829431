function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, function (p) {
      let code = p.charCodeAt(0).toString(16).toUpperCase()
      if (code.length < 2) {
        code = '0' + code
      }
      return '%' + code
    })
  )
}

function base64UrlDecode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/')
  switch (output.length % 4) {
    case 0:
      break
    case 2:
      output += '=='
      break
    case 3:
      output += '='
      break
    default:
      throw 'Illegal base64url string!'
  }

  try {
    return b64DecodeUnicode(output)
  } catch (err) {
    return atob(output)
  }
}

export default function JwtDecode(token: string, options?: any) {
  if (typeof token !== 'string') {
    throw 'Invalid token specified'
  }

  options = options || {}
  const pos = options.header === true ? 0 : 1
  try {
    return JSON.parse(base64UrlDecode(token.split('.')[pos]))
  } catch (e) {
    throw 'Invalid token specified: ' + e.message
  }
}
