import store, { mutationTypes } from '@/store'
import { State } from '@/store/state'
import routesJson from './routes.json'

const routeMaps: FilterRoutes[] = [
  {
    id: 'management',
    name: '管理页面',
    path: '',
    redirect: 'home',
    component: () => import('@/views/index.vue')
  },
  {
    id: 'home',
    name: '首页',
    path: 'home',
    meta: { icon: 'icon-home-icon' },
    component: () => import('@/views/Home/index.vue')
  },
  {
    id: 'home-country',
    name: '首页',
    path: 'home',
    meta: { icon: 'icon-home-icon' },
    component: () => import('@/views/HomeCountry/index.vue')
  },
  {
    id: 'home-data-visualization',
    name: '首页',
    path: 'home',
    meta: { icon: 'icon-home-icon', fullscreen: true },
    component: () => import('@/views/DataVisualization/index.vue')
  },
  {
    id: 'personnel-management',
    name: '人员管理',
    path: 'personnel',
    redirect: 'personnel/info',
    meta: { icon: 'icon-renyuanguanli1' },
    component: () => import('@/views/PersonnelManagement/index.vue')
  },
  {
    id: 'personnel-info',
    name: '人员信息',
    path: 'info',
    component: () => import('@/views/PersonnelManagement/PersonnelInfo/index.vue')
  },
  {
    id: 'tag-management',
    name: '标签管理',
    path: 'tag',
    component: () => import('@/views/PersonnelManagement/TagManagement/index.vue')
  },
  {
    id: 'group-detail',
    name: '到访记录',
    path: 'record',
    component: () => import('@/views/PersonnelManagement/GroupDetail/index.vue')
  },
  {
    id: 'group-statistics',
    name: '到访统计',
    path: 'stat',
    component: () => import('@/views/PersonnelManagement/GroupStatistics/index.vue')
  },
  {
    id: 'data-map',
    name: '区域统计',
    path: 'area-stat',
    meta: {
      auth: ({ config }: State) => config.root?.mapId && !/geo:(\d{6})$/.test(config.root.mapId)
    },
    component: () => import('@/views/PersonnelManagement/DataMap/index.vue')
  },
  {
    id: 'personnel-insight',
    name: '人员洞察',
    path: 'insight',
    redirect: 'insight/recognize',
    meta: { icon: 'icon-person-icon' },
    component: () => import('@/views/PersonnelInsight/index.vue')
  },
  {
    id: 'face-recognition',
    name: '人脸检索',
    path: 'recognize',
    component: () => import('@/views/PersonnelInsight/FaceRecognition/index.vue')
  },
  {
    id: 'face-comparison',
    name: '人脸比对',
    path: 'compare',
    component: () => import('@/views/PersonnelInsight/FaceComparison/index.vue')
  },
  {
    id: 'personnel-search',
    name: '人员查询',
    path: 'search',
    component: () => import('@/views/PersonnelInsight/PersonnelSearch/index.vue')
  },
  {
    id: 'business-function',
    name: '业务功能',
    path: 'business',
    redirect: 'business/vehicle',
    meta: { icon: 'icon-yewugongnengxuanzhong' },
    component: () => import('@/views/BusinessFunction/index.vue')
  },
  {
    id: 'smart-parking',
    name: '智慧车场',
    path: 'vehicle',
    component: () => import('@/views/BusinessFunction/SmartParking/index.vue')
  },
  {
    id: 'population-management',
    name: '人口管理',
    path: 'population',
    redirect: 'population/resident',
    meta: { icon: 'icon-kehuguanli' },
    component: () => import('@/views/PopulationManagement/index.vue')
  },
  {
    id: 'resident-management',
    name: '住户管理',
    path: 'resident',
    component: () => import('@/views/PopulationManagement/ResidentManagement/index.vue')
  },
  {
    id: 'resident-detail',
    name: '住户信息查看',
    path: ':id',
    component: () =>
      import('@/views/PopulationManagement/ResidentManagement/ResidentDetail/index.vue')
  },
  {
    id: 'information-acquisition',
    name: '住户采集',
    path: 'acquisition',
    component: () => import('@/views/PopulationManagement/InformationCollection/index.vue')
  },
  {
    id: 'information-acquisition-ga',
    name: '信息采集',
    path: 'acquisition-ga',
    component: () => import('@/views/PersonnelManagement/InformationCollectionGA/index.vue')
  },
  {
    id: 'tag-management-country',
    name: '标签管理',
    path: 'tag',
    component: () => import('@/views/PopulationManagement/TagManagementCountry/index.vue')
  },
  {
    id: 'expired-renters',
    name: '到期租客',
    path: 'expired',
    component: () => import('@/views/PopulationManagement/ExpiredRenters/index.vue')
  },
  {
    id: 'house-management-level-1',
    name: '房屋管理',
    path: 'house',
    redirect: 'house/manage',
    meta: { icon: 'icon-zufang' },
    component: () => import('@/views/HouseManagement/index.vue')
  },
  {
    id: 'house-management',
    name: '房屋管理',
    path: 'manage',
    component: () => import('@/views/HouseManagement/HouseManagement/index.vue')
  },
  {
    id: 'house-detail',
    name: '房屋信息查看',
    path: ':id',
    component: () => import('@/views/HouseManagement/HouseManagement/HouseDetail/index.vue')
  },
  {
    id: 'pass-management',
    name: '通行管理',
    path: 'pass',
    redirect: 'pass/personnel',
    meta: { icon: 'icon-tonghangfenxi' },
    component: () => import('@/views/PassManagement/index.vue')
  },
  {
    id: 'vehicle-records',
    name: '车辆记录',
    path: 'vehicle',
    component: () => import('@/views/PassManagement/VehicleRecords/index.vue')
  },
  {
    id: 'passing-detail',
    name: '通行记录',
    path: 'personnel',
    component: () => import('@/views/PassManagement/PassingDetail/index.vue')
  },
  {
    id: 'passing-statistic',
    name: '通行统计',
    path: 'stat',
    component: () => import('@/views/PassManagement/PassingStatistic/index.vue')
  },
  {
    id: 'passing-detail-country',
    name: '通行记录',
    path: 'personnel-country',
    props: { moduleName: 'europa', showIdentityAddress: true },
    component: () => import('@/views/PassManagement/PassingDetail/index.vue')
  },
  {
    id: 'passing-statistic-country',
    name: '通行统计',
    path: 'stat-country',
    props: { useKeywordTags: true },
    component: () => import('@/views/PassManagement/PassingStatistic/index.vue')
  },
  {
    id: 'visitor-audit',
    name: '访客审核',
    path: 'review',
    component: () => import('@/views/PassManagement/VisitorAudit/index.vue')
  },
  {
    id: 'visitor-management',
    name: '访客管理',
    path: 'visitors',
    component: () => import('@/views/PassManagement/VisitorManagement/index.vue')
  },
  {
    id: 'event-warning',
    name: '事件预警',
    path: 'event',
    redirect: 'event/inactive',
    meta: { icon: 'icon-shijianyujing' },
    component: () => import('@/views/EventWarning/index.vue')
  },
  {
    id: 'inaction-tenant',
    name: '不活跃租客',
    path: 'inactive',
    component: () => import('@/views/EventWarning/InactionTenant/index.vue')
  },
  {
    id: 'group-leasing',
    name: '群租预警',
    path: 'overcrowd',
    component: () => import('@/views/EventWarning/GroupLeasing/index.vue')
  },
  {
    id: 'abnormal-temperature',
    name: '温度异常',
    path: 'temperature',
    component: () => import('@/views/EventWarning/AbnormalTemperature/index.vue')
  },
  {
    id: 'caring-elder',
    name: '老年人关怀',
    path: 'solitary',
    component: () => import('@/views/EventWarning/CaringElder/index.vue')
  },
  {
    id: 'admin-management',
    name: '后台管理',
    path: 'admin',
    redirect: 'admin/account',
    meta: { icon: 'icon-yunweiguanliweixuanzhong' },
    component: () => import('@/views/AdminManagement/index.vue')
  },
  {
    id: 'account-management',
    name: '账户管理',
    path: 'account',
    meta: {
      auth: ({ user }: State) => user.allowChild
    },
    component: () => import('@/views/AdminManagement/AccountManagement/index.vue')
  },
  {
    id: 'device-management',
    name: '设备管理',
    path: 'device',
    component: () => import('@/views/AdminManagement/DeviceManagement/index.vue')
  },
  {
    id: 'device-detail',
    name: '设备信息查看',
    path: ':id',
    component: () => import('@/views/AdminManagement/DeviceManagement/DeviceDetail/index.vue')
  },
  {
    id: 'area-statistics',
    name: '区域监测',
    path: 'monitor',
    component: () => import('@/views/AdminManagement/SnapStatistics/index.vue')
  },
  {
    id: 'equipment-management',
    name: '设备管理',
    path: 'device',
    component: () => import('@/views/AdminManagement/RegionManagement/index.vue')
  },
  {
    id: 'equipment-repository',
    name: '设备仓库',
    path: 'repository',
    component: () => import('@/views/AdminManagement/EquipmentManagement/index.vue')
  },
  {
    id: 'equipment-config',
    name: '设备配置',
    path: ':id',
    component: () => import('@/views/AdminManagement/EquipmentConfig/index.vue')
  },
  {
    id: 'map-management',
    name: '地图管理',
    path: 'map',
    meta: {
      auth: ({ config }: State) => config.root?.mapId && !/geo:(\d{6})$/.test(config.root.mapId)
    },
    component: () => import('@/views/AdminManagement/MapManagement/index.vue')
  },
  {
    id: 'smart-realty',
    name: '智慧案场',
    path: 'realty',
    meta: { icon: 'icon-manage-icon' },
    component: () => import('@/views/SmartRealty/index.vue')
  },
  {
    id: 'client-appointment',
    name: '客户报备',
    path: 'report',
    component: () => import('@/views/SmartRealty/ClientAppointment/index.vue')
  },
  {
    id: 'risk-determination',
    name: '风险判定',
    path: 'risk',
    component: () => import('@/views/SmartRealty/RiskDetermination/index.vue')
  },
  {
    id: 'visitor-search',
    name: '到访查询',
    path: 'search',
    component: () => import('@/views/SmartRealty/VisitorSearch/index.vue')
  },
  {
    id: 'cube',
    name: '迎宾魔方',
    path: 'cube',
    meta: { icon: 'icon-cube-icon', fullscreen: true },
    component: () => import('@/views/Cube/index.vue')
  },
  {
    id: 'data-visualization',
    name: '数据大屏',
    path: 'data-visualization',
    meta: { icon: 'icon-cube-icon', fullscreen: true },
    component: () => import('@/views/DataVisualization/index.vue')
  },
  {
    id: 'notfound',
    name: '无',
    path: '/notfound',
    component: () => import('@/views/NotFoundPage.vue')
  },
  {
    id: '404',
    name: '404',
    path: '*',
    component: () => import('@/views/NotFoundPage.vue')
  }
]

const mergeRoutes = (routes: any) => {
  if (routes instanceof Array) {
    routes.forEach(route => {
      mergeRoutes(route)
    })
  } else {
    const restRoutes = routeMaps.filter(route => route.id === routes.id)[0] || {}
    Object.assign(routes, restRoutes)

    if (routes.children) {
      routes.children.forEach((route: any) => {
        mergeRoutes(route)
      })
    }
  }
}

const replenishRoutes = [
  {
    id: 'notfound',
    label: '无'
  },
  {
    id: '404',
    label: '404'
  }
]

const routes: any[] = [...replenishRoutes, routesJson]
mergeRoutes(routes)

export const filterRoutes = (features?: RoutePath) => {
  const path: string[] = []

  function expandFeatures(features: RoutePath) {
    if (features.children) {
      features.children.forEach(feature => {
        expandFeatures(feature)
      })
    } else {
      if (features.id === 'equipment-repository') {
        path.push('equipment-config')
      }
      path.push(features.id)
    }
  }

  function filter(routes: FilterRoutes[]) {
    // 递归移除叶子结点
    for (let idx = 0; idx < routes.length; idx++) {
      const route: any = routes[idx]
      if (route.children) {
        filter(route.children)
      } else {
        if (!path.includes(route.id)) {
          routes.splice(idx--, 1)
        }
      }
    }

    // 移除所有children为空数组的结点
    for (let idx = 0; idx < routes.length; idx++) {
      const route: any = routes[idx]
      if (route.children && route.children.length === 0) {
        routes.splice(idx--, 1)
      }
    }
  }

  if (features && features.id) {
    expandFeatures(features)
  }

  // 规范并检测是否需要重写redirect属性
  function normalizeRedirect(routes: any) {
    if (routes instanceof Array) {
      for (const route of routes) {
        normalizeRedirect(route)
      }
    } else {
      if (routes.redirect && routes.children) {
        const redirectArray = routes.redirect.split('/')
        const lastIndex = redirectArray.length - 1
        const lastRedirect = redirectArray[lastIndex]
        const redirect = routes.children.filter((r: any) => r.path === lastRedirect)
        if (!redirect[0]) {
          const lastPath = routes.children[0].path
          redirectArray.splice(lastIndex, 1, lastPath)
        }
        routes.redirect = redirectArray.join('/')
        normalizeRedirect(routes.children)
      }
    }
  }
  store.commit(mutationTypes.SET_FEATURES, { path })
  if (routes && path.length) {
    routes.forEach((route: any) => {
      if (route.children) {
        filter(route.children)
        normalizeRedirect(route)
      }
    })
  }

  return routes
}

export default routes
