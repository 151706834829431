import { Request } from './request'

export class Devices extends Request {
  constructor() {
    super('/base/devices')
  }
  getAttributes(id: string, query: string, scope: string) {
    return this.post(`/${id}/attributes`, { scope, values: query })
  }

  getTemplate(type: string) {
    return this.get(`/types/${type}/template`)
  }

  putAttributes(id: string, data: any) {
    return this.put(`/${id}/attributes`, data)
  }
}

export default new Devices()
