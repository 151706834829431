import { sync } from 'vuex-router-sync'
import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import message from '@/components/common/Message'
import { api, axios } from '@/api'
import store, { mutationTypes } from './store'
import login from '@/login'
import confirm from '@/components/common/Confirm'
import formCreate from '@form-create/element-ui'

import 'babel-polyfill'
import '@/plugins/element'
import '@/plugins/custom-components'
import '@/assets/images/font/iconfont.css'
import '@/assets/css/global.css'
import '@/styles/app.scss'
import ws from './libs/socket-web'

window.Promise = Promise
Vue.use(formCreate)
Vue.prototype.$http = axios
Vue.prototype.$api = api
Vue.prototype.$message = message
Vue.prototype.$moment = moment
Vue.prototype.$ws = ws
Vue.prototype.$confirm = confirm

login().then(({ isLogin, router }) => {
  if (isLogin && router) {
    sync(store, router)

    const app = new Vue({
      store,
      router,
      render: h => h(App)
    })
    app.$mount('#app')

    store.commit(mutationTypes.SET_APP, app)
  }
})
