import { Request } from './request'

export class Static extends Request {
  constructor() {
    super('/static', { baseURL: '' })
  }

  getGeoJson(adcode = 110000) {
    return this.get(`/geojson/${adcode}.json`)
  }

  getColorJson() {
    return this.get('/color.json')
  }

  getVehicleBrands() {
    return this.get('/json/vehicle-brands.json')
  }

  getCitizenship() {
    return this.get('/json/citizenship.json')
  }
}

export default new Static()
